import { useQuery } from "@apollo/client";
import React, { useState } from "react"
import { useEffect } from "react"
import { numberWithCommas } from "../../functions/myFunctions";
import { GET_COMMUNITY_MEMBERS } from "../../queries";
import { getOneCommunityFb } from "../../singletons/Firebase"
import verifiedPurpleImg from "../../images/verificationPurple.png";
import verifiedBlueImg from "../../images/verificationBlue.png";
import { Typewriter } from 'react-simple-typewriter';
import shining from '../../images/shining.json';
import Wallets from '../../images/Wallets.json'
import Lottie from "lottie-react";



const HeroCard = ({ }) => {
  const [communityName, setCommunityName] = useState('')
  const [communityAvatar, setCommunityAvatar] = useState('')
  const [communityBg, setCommunityBg] = useState<any>('')
  const [totalMembers, setTotalMembers] = useState(0)
  const [totalXP, setTotalXp] = useState(0)
  const [heroImageLoaded, setHeroImageLoaded] =useState(false);



  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {

  }

  return (
    <div className="hero-card" onClick={() => { }}>
      <img src={require('../../images/HERO2.png')} className='featured-card-bg' alt='' />
      <div className="hero-card-main-content" style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', position: 'relative', width: '100%', height: '100%', padding: '50px', paddingTop: '80px' }}>

        <div className="xp-logo-container-1" style={{ justifyContent: 'center', flexDirection: 'column', alignItems: 'center', }}>
          <img src={require('../../images/biglogo4.png')} style={{display: heroImageLoaded ? 'flex':'none'}}className='bg-logo' alt=''  onLoad={()=>{setHeroImageLoaded(true)}}/>
        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'flex-start', }}>

          <span style={{ color: 'white', fontSize: 40, marginBottom: -10, textShadow: '0px 0px 30px rgba(0,0,0,.8)' }}>
            <Typewriter
              loop
              cursor
              cursorStyle='_'
              typeSpeed={70}
              deleteSpeed={30}
              delaySpeed={1300}
              words={['Level Up', 'Track', 'Incentivize', 'Reward']}

            />
          </span>
          <span style={{ color: 'white', fontSize: 30, marginBottom: -3, textShadow: '0px 0px 20px rgba(0,0,0,.8)' }}>your community</span>
          <span style={{ color: 'white', fontSize: 18, textShadow: '0px 0px 20px rgba(0,0,0,.8)' }}>wtih a custom on-chain point system</span>
          <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', alignItems: 'center', height: 80 }}>
            <div onClick={()=>{window.open('https://huddln.io/xp')}} className="action-button create-xp-btn" style={{ display: 'flex', color: '#363636', justifyContent: 'center', flexDirection: 'row', alignItems: 'center', backgroundColor: 'rgba(255,255,255,1)', borderRadius: 50, height: 40, width: 130 }}>
              Learn more
            </div>
            <div onClick={()=>{window.open('https://dashboard.huddln.io/create-project/')}} className="action-button create-xp-btn" style={{ color: 'white', display: 'flex', justifyContent: 'center', flexDirection: 'row', alignItems: 'center', borderRadius: 50, height: 40, width: 210, marginLeft: 10 }}>
              Create an XP instance
            </div>

          </div>



        </div>
        <div className="xp-logo-container" style={{position:'relative',justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
          <img src={require('../../images/biglogo4.png')} className='bg-logo' alt='' />
          <div style={{ position: 'absolute', top: '0%',bottom:'0%',left:'0%', right: '0%' }}>
            <Lottie
              animationData={shining}
              loop={true}
              style={{
                color: 'white',
                height: '100%',
                
                filter: 'drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.9))',
              }}
            />
          </div>
        </div>


      </div>
     
      <div className="">
        <div className="">


        </div>

      </div>


    </div>
  )
}


export default React.memo(HeroCard)