import "../styles/UserDetails.css";

import { useState, useEffect } from "react";
import { getDateFromTimestamp } from "../utility";
import { useParams } from "react-router-dom";
import bg from "../images/bg1.png";
import {
  firestoreGetUserInfoV4,
} from "../utility";
import { ethers } from "ethers";
import { useQuery, useApolloClient } from "@apollo/client";
import Linkify from "react-linkify";
import verifiedPurpleImg from "../images/verificationPurple.png";
import verifiedBlueImg from "../images/verificationBlue.png";
import { BsClipboard, BsYoutube } from "react-icons/bs";
import { FiTwitter, FiInstagram } from "react-icons/fi";
import { FaDiscord, FaSpotify, FaTwitch, FaTiktok } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";

import identicon1 from "../images/avatar9.png";
import identicon2 from "../images/avatar8.png";
import identicon3 from "../images/avatar7.png";
import identicon4 from "../images/avatar6.png";
import identicon5 from "../images/avatar5.png";
import identicon6 from "../images/avatar4.png";
import identicon7 from "../images/avatar3.png";
import identicon8 from "../images/avatar22.png";
import identicon9 from "../images/avatar23.png";
import { GET_SCORE_TYPE, GET_SCORE_UPDATES, GET_TOTAL_SCORE, GET_USER_COMMUNITIES } from "../queries";
import { capitalizeWords, truncate, } from "../functions/myFunctions";
import { Zoom, toast } from 'react-toastify';

const identicons = [
  identicon1,
  identicon2,
  identicon3,
  identicon4,
  identicon5,
  identicon6,
  identicon7,
  identicon8,
  identicon9,
];

const UserDetails = () => {
  const client = useApolloClient();
  const { userId: address, projectId } = useParams();

  const [profileImage, setProfileImage] = useState<string>();
  const [backgroundUrl, setBackgroundUrl] = useState<string>();
  const [username, setUsername] = useState<string>();
  const [userBio, setUserBio] = useState<string>();
  const [fullName, setFullName] = useState<string>();
  const [scoreUpdateData, setScoreUpdateData] = useState<any>([]);
  const [totalScore, setTotalScore] = useState<number>();
  const [scoreTypes, setScoreTypes] = useState<string[]>();
  const [currentScoreType, setCurrentScoreType] = useState<string>("All");
  const [addressOnlyUser, setAddressOnlyUser] = useState<boolean>(true);
  const [imageZoomOpen, setImageZoomOpen] = useState<boolean>(false);
  const [verifiedLevel, setVerifiedLevel] = useState<number>();
  const [userProjects, setUserProjects] = useState<any>([]);
  const [currentProject, setCurrentProject] = useState<string>();
  const [twitter, setTwitter] = useState<string>();
  const [discord, setDiscord] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [twitch, setTwitch] = useState<string>();
  const [youtube, setYoutube] = useState<string>();
  const [spotify, setSpotify] = useState<string>();
  const [tiktok, setTiktok] = useState<string>();
  const [instagram, setInstagram] = useState<string>();

  const { data: userProjectResults } = useQuery(GET_USER_COMMUNITIES, {
    variables: { address },
  });

  useEffect(() => {
    //here
    if (userProjectResults) {
      let projects = userProjectResults.scoreboards.map(
        (scoreboard: any) => scoreboard.project
      );
      setUserProjects(projects);
    }
  }, [userProjectResults]);

  useEffect(() => {
    if (projectId) {
      setCurrentProject(projectId);
    } else if (userProjects[0]?.id) {
      setCurrentProject(userProjects[0]?.id);
    }
  }, [userProjects]);

  useEffect(() => {
    if (currentProject) {
      getTotalScore();
      getScoreTypes();
      getScoreUpdates();
    }
  }, [currentProject]);

  useEffect(() => {
    getUserDetails();
  }, [address]);

  const getTotalScore = async () => {
    const totalScoreResult = await client.query({
      query: GET_TOTAL_SCORE,
      variables: {
        projectId: currentProject,
        address,
      },
    });

    setTotalScore(totalScoreResult.data.scoreboards[0].totalScore);
  };

  const getScoreTypes = async () => {
    const scoreTypeResult = await client.query({
      query: GET_SCORE_TYPE,
      variables: {
        projectId: currentProject,
        address,
      },
    });

    let types = scoreTypeResult.data.scores.map(
      (score: any) => score.scoreType
    );
    setScoreTypes(["All", ...types]);
  };

  const getScoreUpdates = async () => {
    let resultsArray = []
    for (let x = 0; x < 100; x = x + 50) {
      const scoreUpdateResult = await client.query({
        query: GET_SCORE_UPDATES,

        variables: {
          projectId: currentProject,
          address,
          first: 50,
          skip: x
        },
      });
      resultsArray.push(...scoreUpdateResult.data.scoreUpdates)

    }
    setScoreUpdateData([...scoreUpdateData, ...resultsArray])
  };

  const getUserDetails = async () => {
    let rand = Math.floor(Math.random() * 8);
    let checksumAddress;
    try {

      if (address) {
        checksumAddress = ethers.utils.getAddress(address);
      } else {
        return;
      }
      setBackgroundUrl(bg);
      setProfileImage(identicons[rand]);

      let userProfile = await firestoreGetUserInfoV4(checksumAddress);
      console.log(userProfile)
      if (userProfile) {

        if (userProfile.userName) {
          setAddressOnlyUser(false);
          setUsername(userProfile.userName);
        }

        if (userProfile.userImageURI) {
          setProfileImage(userProfile.userImageURI);
        }
        //user image fallback 1
        if (!userProfile.userImageURI && userProfile?.socials?.discordProfile_info?.avatar) {
          setProfileImage(userProfile?.socials?.discordProfile_info?.avatar);
        }
        if (userProfile.fullName) {
          setFullName(capitalizeWords(userProfile.fullName));
        }
        //fallback 1 userProfile.fullName
        if (!userProfile.fullName && userProfile?.socials?.discordProfile_info?.username) {
          setAddressOnlyUser(false);
          setFullName(capitalizeWords(userProfile?.socials?.discordProfile_info?.username));
        }

        if (userProfile.socials) {
          if (userProfile.socials.backgroundImage) {
            setBackgroundUrl(userProfile.socials.backgroundImage);
          }
          else {
            setBackgroundUrl(bg);
          }
          if (userProfile.socials.bio) {
            setUserBio(userProfile.socials.bio);
          }
          if (userProfile.socials.discord) {
            setDiscord(userProfile.socials.discord);
          }
          else if (userProfile.socials.discord_id) {

            setDiscord("https://discordapp.com/users/" + userProfile.socials.discord_id);
          }
          if (userProfile.socials.email) {
            setEmail(userProfile.socials.email);
          }
          if (userProfile.socials.instagram) {
            setInstagram(userProfile.socials.instagram);
          }
          if (userProfile.socials.tiktok) {
            setTiktok(userProfile.socials.tiktok);
          }
          if (userProfile.socials.twitch) {
            setTwitch(userProfile.socials.twitch);
          }
          if (userProfile.socials.twitter) {
            setTwitter(userProfile.socials.twitter);
          }
          if (userProfile.socials.youtube) {
            setYoutube(userProfile.socials.youtube);
          }
        } else {
          setBackgroundUrl(bg);
        }
        if (userProfile.verification) {
          setVerifiedLevel(userProfile.verification.level);
        }
      } else {
        setAddressOnlyUser(true);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const selectProject = (event: any) => {
    setCurrentProject(event.target.value)
  }

  const copyClipboard = () => {
    if (address) {
      navigator.clipboard.writeText(address);
      toast(`Address copied`, {
        position: "top-center",
        autoClose: 750,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Zoom
      })
    }
  }

  return (
    <div className="user-details-container">
      <div
        style={{ backgroundImage: `url(${backgroundUrl})`, }}
        className="header-image"
      ></div>
      <div className="user-details">
        <div className="user-details-top">
          <div style={{ width: "95%" }}>
            <div className="user-header">
              <div
                className="avatar-container"
                onClick={() => setImageZoomOpen(!imageZoomOpen)}
              >
                <img style={{ objectFit: 'cover' }} src={profileImage} className="avatar" />
              </div>

              <div className="verified-lottie">
                {verifiedLevel === 2 && (
                  <img
                    style={{ height: 25, width: 25 }}
                    src={verifiedBlueImg}
                  />
                )}
                {verifiedLevel === 3 && (
                  <img
                    style={{ height: 25, width: 25 }}
                    src={verifiedPurpleImg}
                  />
                )}
              </div>
              <div className="user-header-name">
                {addressOnlyUser ? (
                  <div
                    className="address-container"
                    style={{ marginTop: "40px" }}
                    onClick={copyClipboard}
                  >
                    <div className="address-label">ID</div>
                    <div className="address">
                      {address && truncate(address, 20, "...")}
                    </div>
                    <div className="address-clipboard">
                      <BsClipboard />
                    </div>
                  </div>
                ) : (
                  <>
                    <h1 style={{
                      fontSmooth: 'always',
                      WebkitFontSmoothing: 'always',
                      WebkitTextStroke: '0.3px'

                    }}>{fullName}</h1>
                    <h2>{username}</h2>
                  </>
                )}
              </div>
            </div>
            {!addressOnlyUser && (
              <div className="address-button-container">
                <div
                  className="address-container"
                  onClick={copyClipboard}
                >
                  <div className="address-label">ID</div>
                  <div className="address">
                    {address && truncate(address, 12, "...")}
                  </div>
                  <div className="address-clipboard">
                    <BsClipboard />
                  </div>
                </div>
              </div>
            )}
            {userBio && (
              <div className="user-bio">
                <Linkify>{userBio}</Linkify>
              </div>
            )}
          </div>
          <div className="user-social-icons">
            {/* <FiShare /> */}
            {twitter && (
              <a className="social-icon" href={twitter} target="_blank">
                <FiTwitter />
              </a>
            )}
            {spotify && (
              <a className="social-icon" href={discord} target="_blank">
                <FaSpotify />
              </a>
            )}
            {discord && (
              <a className="social-icon" href={discord} target="_blank">
                <FaDiscord />
              </a>
            )}
            {email && (
              <a className="social-icon" href={"mailto:" + email}>
                <MdOutlineEmail />
              </a>
            )}
            {youtube && (
              <a className="social-icon" href={youtube} target="_blank">
                <BsYoutube />
              </a>
            )}
            {instagram && (
              <a className="social-icon" href={instagram} target="_blank">
                <FiInstagram />
              </a>
            )}
            {tiktok && (
              <a className="social-icon" href={tiktok} target="_blank">
                <FaTiktok />
              </a>
            )}
            {twitch && (
              <a className="social-icon" href={twitch} target="_blank">
                <FaTwitch />
              </a>
            )}
          </div>
        </div>
        <div className="user-total-score-container">
          <div className="select-project">
            <div className="select-project-title">Communities</div>
            {userProjects && (
              <select
                onChange={selectProject}
                value={currentProject}
              >
                {userProjects.map((project: any, index: number) => (
                  <option key={index} value={project.id}>{project.name}</option>
                ))}
              </select>
            )}
          </div>
          <div className="user-total-score">
            Total XP
            <div className="user-total-score-number">
              {totalScore &&
                parseInt(totalScore.toString()).toLocaleString("en-US")}
            </div>
          </div>
        </div>
        <div className="user-scoretype-tabs-container">
          {scoreTypes &&
            scoreTypes.map((type, index) => (
              <div
                key={index}
                className={
                  currentScoreType === type
                    ? "user-scoretype-tab selected"
                    : "user-scoretype-tab"
                }
                onClick={() => setCurrentScoreType(type)}
              >
                {type}
                {currentScoreType === type && (
                  <div className="under-selected-tab"></div>
                )}
              </div>
            ))}
        </div>
        <div className="score-update-container">
          {
            scoreUpdateData &&
            scoreUpdateData.filter(
              (update: any) =>
                update.scoreType === currentScoreType ||
                currentScoreType === "All"
            )
              .map((update: any, index: number) => (
                <div key={index} className="score-update">
                  <div className="score-update-left">
                    <div className="score-update-date">
                      {update.createdAt &&
                        getDateFromTimestamp(update.createdAt)}
                    </div>
                    <div className="score-update-action">
                      {update.actionName}
                    </div>
                  </div>
                  <div className="score-update-points">
                    {update?.direction == 1 && '-'}
                    {update.pointChange} XP
                  </div>
                </div>
              ))}
        </div>
      </div>
      {/* <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      /> */}
    </div>
  );
};

export default UserDetails;
