import Marquee from "react-fast-marquee";
import UserAvatar from "../UserAvatar/UserAvatar";

interface Props {
  title?: string;
  address: string | undefined;
  displayXp: string[];
}

function ScoreBanner({
  title = 'My Scoreboard in this Community',
  address,
  displayXp
}: Props) {

  return (
    <>
      <h1 className="font-heading mt-[2px] w-full text-white text-base text-shadow text-left">{title}</h1>
      <div className="w-full relative mt-[2px]">
        <Marquee
          gradient={true} gradientColor={[206, 124, 250]} gradientWidth={100} speed={40}
          className={`rounded-s bg-huddlnPurple h-[50px] shadow-md ${Boolean(address) && 'rounded-tl-3xl rounded-bl-3xl'}`}>
          {!Boolean(address) &&
            <h1 className="font-body text-white text-lg ml-[40px] text-shadow">Connect wallet to see your XP</h1>
          }
          {Boolean(address) && displayXp.map((t, i) => {
            let [title, score] = t.split(':');
            let formattedTitle = title.charAt(0).toUpperCase() + title.slice(1).toLowerCase();
            let scoreFormatted = parseInt(score.split('XP')[0]).toLocaleString();

            if (formattedTitle === "All") {
              formattedTitle = "Total";
            }
            return (
              <h1 key={i} className="font-body text-white text-lg ml-[40px] text-shadow">
                {formattedTitle + ": "}
                <span className='font-heading'>{scoreFormatted + " XP"}</span>
              </h1>)

          })}
        </Marquee>
        {address && (
          <div className="absolute top-0 bottom-0 left-0 z-10">
            <UserAvatar
              size={50}
              shadowColor="black"
              address={address}
              withVerification={false}
              onClick={() => { }}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default ScoreBanner