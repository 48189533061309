import React, { useState, useEffect } from "react";
import "../styles/ScoreRow.css";
import { ethers } from "ethers";

import { firestoreGetUserInfoV4, } from "../utility";
import { truncate } from '../functions/myFunctions'
import UserAvatar from "./UserAvatar/UserAvatar";
import identicon1 from "../images/avatar9.png";
import identicon2 from "../images/avatar8.png";
import identicon3 from "../images/avatar7.png";
import identicon4 from "../images/avatar6.png";
import identicon5 from "../images/avatar5.png";
import identicon6 from "../images/avatar4.png";
import identicon7 from "../images/avatar3.png";
import identicon8 from "../images/avatar22.png";
import identicon9 from "../images/avatar23.png";
import { Zoom, toast } from 'react-toastify';
import { BsClipboard } from "react-icons/bs";
import algoliasearch from "algoliasearch";
import { useNavigate } from "react-router-dom";

const identicons = [
  identicon1,
  identicon2,
  identicon3,
  identicon4,
  identicon5,
  identicon6,
  identicon7,
  identicon8,
  identicon9,
];

type ScoreRowProps = {
  projectId: string | undefined;
  address: string;
  points: string | undefined;
  rank: number;
  userInfo: any;
};

const ALGOLIA = {
  appId: "VEZHZ5YNG5",
  apiKey: "1e61b997c8f67306166c50b1990cdf0b",
};
const Algolia_Client = algoliasearch(ALGOLIA.appId, ALGOLIA.apiKey);
const Users_Public_Index = Algolia_Client.initIndex("users_public");

const ScoreRow = ({ projectId, address, points, rank, userInfo }: ScoreRowProps) => {
  const navigate = useNavigate();

  const [username, setUsername] = useState<string>('');
  const [profileImage, setProfileImage] = useState<string>();
  const [verifiedLevel, setVerifiedLevel] = useState<number>();

  useEffect(() => {
    const getUserDetails = async () => {
      try {


        let rand = Math.floor(Math.random() * 8);
        let checksumAddress;

        if (address) {
          checksumAddress = ethers.utils.getAddress(address);
        } else {
          return;
        }

        setProfileImage(identicons[rand]);

        let userProfile = await firestoreGetUserInfoV4(checksumAddress);
        // console.log(userProfile);

        if (userProfile) {
          if (userProfile?.userName) {
            setUsername(userProfile.userName);
          }
          if (userProfile?.userImageURI) {
            setProfileImage(userProfile.userImageURI_128x128);
          }
          if (userProfile?.verification) {
            setVerifiedLevel(userProfile.verification?.level);
          }
        }
      } catch (error) {
        console.log(error)
      }
    };

    getUserDetails();
  }, []);

  const navToUser = (a: any) => {
    navigate(
      `/user/${a.toLowerCase()}/${projectId}`
    );
  }

  const copyClipboard = () => {
    navigator.clipboard.writeText(address);
    toast(`Address copied`, {
      position: "top-center",
      autoClose: 750,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Zoom
    })
  }


  return (
    <div className="score-row-clip">
      <div className="score-row" >
        <div
          className="score-row-left"
        >
          <div className="score-row-rank gradient-text">{rank}</div>
          <div onClick={() => navToUser(address)} style={{ display: 'flex', flexDirection: 'row' }}>
            <UserAvatar
              borderWidth="4px"
              enableShadow={false}
              enableInfo={false}
              size={'3em'}
              infoPlacement={'side'}
              address={address} />
            {username &&
              <div className="score-row-display-name">
                {username}
              </div>}
          </div>
          {!username && userInfo?.socials?.discordProfile_info?.username &&
            <div className="score-row-display-name">
              {userInfo.socials.discordProfile_info.username}
            </div>}

          {!username && !userInfo?.socials?.discordProfile_info?.username &&
            <div className="score-row-display-name" onClick={copyClipboard}>
              <BsClipboard onClick={copyClipboard} style={{ marginRight: 10 }} />
              {truncate(address, 24, "...")}
            </div>}


          {username && (
            <div className="score-row-address" onClick={copyClipboard}>
              <BsClipboard style={{ marginRight: 10 }} />
              {truncate(address, 24, "...")}
            </div>
          )}
        </div>
        <div className="score-row-xp gradient-text">
          {points && parseInt(points).toLocaleString("en-US")} XP
        </div>
      </div>
    </div>
  );
};

export default ScoreRow;
