import algoliasearch from "algoliasearch";
import { projectRealtimeDb } from "./firebase/config";

const ALGOLIA = {
  appId: "VEZHZ5YNG5",
  apiKey: "1e61b997c8f67306166c50b1990cdf0b",
};
const Algolia_Client = algoliasearch(ALGOLIA.appId, ALGOLIA.apiKey);
const Users_Public_Index = Algolia_Client.initIndex("users_public");

export const getDateFromTimestamp = (timestamp: number) => {
  // let timestamp = time.seconds;
  var date = new Date(timestamp * 1000);
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var year = date.getFullYear();

  var fullDate = month + "/" + day + "/" + year;
  return fullDate;
};


export const firestoreGetUserInfoV4 = async (uid: any) => {
  const alogoliaResponse = await Users_Public_Index.getObject(uid);
  // console.log(alogoliaResponse)
  if (!alogoliaResponse) {
    const firebaseResponse = await projectRealtimeDb
      .ref("/users_public/" + uid)
      .once("value");

    return firebaseResponse.val();
  }

  return alogoliaResponse;
};

export const firestoreCheckIfUsernameIsUniqueV2 = (userName: any) => {
  // only query string
  return Users_Public_Index.search(userName, {
    exactOnSingleWordQuery: "attribute",
    queryType: "prefixNone",
    restrictSearchableAttributes: ["userName"],
    typoTolerance: false,
    // searchableAttributes:['username'],
    // exactOnSingleWordQuery: 'attribute'
    // exactOnSingleWordQuery: 'word'
    // attributesToRetrieve: ["userName"],
    hitsPerPage: 10,
  }).then(({ hits }) => {
    if (hits.length == 0) {
      return true;
    } else {
      return false;
    }
  });
};

export const firestoreGetUserInfoV3 = (
  uid: any,
  callback: any,
  failureCallback: any
) => {
  const promise1 = Users_Public_Index.getObject(uid).then((snapshot) => ({
    ...snapshot,
    uid,
  }));

  const promise2 = projectRealtimeDb
    .ref("/users_public/" + uid)
    .once("value")
    .then((snapshot) => ({
      ...snapshot.val(),
      uid,
    }));

  return Promise.race([promise1, promise2])
    .then((value) => {
      return callback(value);
    })
    .catch((err) => {
      console.log("err: ");
      console.log(err);
      failureCallback();
    });
};
