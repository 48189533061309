import React from 'react'
import { ImSpinner8 } from "react-icons/im";
import Lottie from "lottie-react";
import checkmark from '../../images/Checkmark_anim.json'
import errorLottie from '../../images/error.json'

interface Props {
  alreadyEarned: boolean;
  btnTxt: string;
  btnState: string;
  isPurchaseable: boolean
  redeemHandler: any;
  timeDone: boolean;
  progressBarAnim: number;
  progressBar: number;
}

export default function RedemptionBtn({
  alreadyEarned, btnTxt, btnState, isPurchaseable, redeemHandler, timeDone, progressBarAnim, progressBar
}: Props) {
  return (
    <>    {
      alreadyEarned ? (
        <div className="redeem-handler-progress-contain" >
          <h1 >{btnTxt}</h1>
        </div>
      ) : btnState === 'loading' ? (
        <div className="redeem-handler-progress-contain" style={{ backgroundColor: "#A654F4" }}>
          {/* <img src={loading} alt='' height={14} style={{ marginRight: 10 }} /> */}
          <ImSpinner8 size={14} color="white" className="redeem-spinner" />
          <h1>{isPurchaseable ? 'Buying...' : 'Collecting...'}</h1>
        </div>
      ) : btnState === 'success' ? (
        <div className="redeem-handler-progress-contain">
          <Lottie
            animationData={checkmark}
            autoPlay={true}
            initialSegment={[0, 130]}
            loop={false}
            style={{
              marginLeft: -30,
              marginRight: -10,
              width: 56,
              height: 56,
            }}
          />
          <h1>{isPurchaseable ? 'Bought!' : 'Collected!'}</h1>
        </div>
      ) : btnState === 'error' ? (
        <div className="redeem-handler-progress-contain" onClick={redeemHandler}>
          <Lottie
            animationData={errorLottie}
            autoPlay={true}
            // initialSegment={[0, 130]}
            loop={false}
            style={{
              marginLeft: -10,
              // marginRight: ,
              width: 30,
              height: 30,
            }}
          />
          <h1>Try Again</h1>
        </div>
      ) : (
        <div className="redeem-handler-progress-contain">
          <div className="redeem-handler-progress" style={{ transition: `all ${progressBarAnim}s ease-out`, width: `${progressBar.toString()}%` }} />
          {timeDone ? (
            <h1 onClick={redeemHandler} style={{ color: 'white' }}>{btnTxt}</h1>
          )
            : (
              <h1 style={{ color: 'white' }}>Calculating XP...</h1>
            )}
        </div>
      )
    }
    </>
  )
}
