import eth from "../images/ETH_COLOR.png";
import polygon from "../images/polygon_color.png";
import arbitrum from "../images/arbitrum-arb-logo.png";
import arbitrumNova from "../images/arbitrum-nova.png";
import optimism from "../images/optimism-logo.png";
import bsc from "../images/bnb-bnb-logo.png";
import avalanche from "../images/avalanche-avax-logo.png";
import Web3 from "web3";
import ERC20 from "../abi/ERC20.json";
import erc20RedemptionABI from "../abi/ERC20Redemption.json";
import { ethers } from "ethers";
import getWeb3 from "../web3/getWeb3";

type WatcherNetwork = {
  name: string,
  displayName: string,
  chainName: string,
  nativeCurrencyName: string,
  symbol: string,
  logo: string,
  rpcUri: string,
  publicRpcUri: string,
  explorerUri: string,
  graphUri: string,
  alchemy: string,
  chainId: number,
  chainIdOx: string,
  redemptionContract: string,
};

const ALCHEMY_API_KEY = '83uAJ90PdMxTEMuwg3zIHj9ZG-CXD_rH'

export const SUPPORTED_WATCHER_NETWORKS: WatcherNetwork[] = [
  {
    name: "ethereum",
    displayName: "Ethereum",
    chainName: "Ethereum",
    nativeCurrencyName: "ETH",
    symbol: "ETH",
    logo: eth,
    rpcUri:
      "https://chaotic-solitary-wave.quiknode.pro/f4c7767687e3654059ef21fc64229adb515d6fc5/",
    publicRpcUri: "https://chaotic-solitary-wave.quiknode.pro/f4c7767687e3654059ef21fc64229adb515d6fc5/c",
    explorerUri: "https://etherscan.io",
    graphUri:
      "https://api.studio.thegraph.com/query/33416/erc-20-redemptions-eth/version/latest",
    alchemy: `https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_API_KEY}`,
    chainId: 1,
    chainIdOx: "0x1",
    redemptionContract: "0x9093608470EBC5B5daF3eE5eE30246CF13AeAC1D",
  },
  {
    name: "polygon",
    displayName: "Polygon",
    chainName: "Polygon Mainnet",
    nativeCurrencyName: "MATIC",
    symbol: "",
    logo: polygon,
    rpcUri:
      "https://floral-polished-flower.matic.quiknode.pro/a6acd38622de9dd3f1ebdd8144a2f1f157ab1285/",
    publicRpcUri: "https://polygon-bor.publicnode.com",
    explorerUri: "https://polygonscan.com",
    graphUri: "https://api.studio.thegraph.com/query/33416/erc-20-redemption-polygon-v2/version/latest",
    alchemy: `https://polygon-mainnet.g.alchemy.com/v2/${ALCHEMY_API_KEY}`,
    chainId: 137,
    chainIdOx: "0x89",
    redemptionContract: "0x0981EA28F0D8f3aFa7b2b8eD5BD7DE92c2A894a6",
  },
  {
    name: "arbitrum",
    displayName: "Arbitrum One",
    chainName: "Arbitrum One",
    nativeCurrencyName: "ETH",
    symbol: "",
    logo: arbitrum,
    rpcUri:
      "https://powerful-tame-friday.arbitrum-mainnet.quiknode.pro/db3a450bf6aee217aaca70c7c7185481f8688e28/",
    publicRpcUri: "https://arbitrum-one.publicnode.com",
    explorerUri: "https://arbiscan.io",
    graphUri: "https://api.studio.thegraph.com/query/33416/erc-20-redemptions-arb-one/version/latest",
    alchemy: `https://arb-mainnet.g.alchemy.com/v2/${ALCHEMY_API_KEY}`,
    chainId: 42161,
    chainIdOx: "0xa4b1",
    redemptionContract: "0xbd4cf38B4fB04e2A9Ee5FaB4ca3f6DceAF48Cc7A",
  },
  /*
  {
    name: "arbitrum-nova",
    displayName: "Arbitrum Nova",
    chainName: "Arbitrum Nova",
    nativeCurrencyName: "ETH",
    symbol: "",
    logo: arbitrumNova,
    rpcUri:
      "https://red-greatest-hexagon.nova-mainnet.quiknode.pro/cc663fe543679e962555781b77cccc2bb35a2a59/",
    publicRpcUri: "https://arbitrum-nova.publicnode.com",
    explorerUri: "https://nova.arbiscan.io",
    graphUri: "",
    alchemy: `https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_API_KEY}`, //REPLACE
    chainId: 42170,
    chainIdOx: "0xa4ba",
    redemptionContract: "",
  },*/
  {
    name: "optimism",
    displayName: "Optimism",
    chainName: "OP Mainnet",
    nativeCurrencyName: "ETH",
    symbol: "ETH",
    logo: optimism,
    rpcUri:
      "https://still-cosmopolitan-pond.optimism.quiknode.pro/62b89bf9306a7f9d00c9de61b2ae0c48eb3e75d2/",
    publicRpcUri: "https://optimism.publicnode.com",
    explorerUri: "https://optimistic.etherscan.io",
    graphUri: "https://api.thegraph.com/subgraphs/name/joegonzalez0886/erc-20-redemptions-optimism",
    alchemy: `https://opt-mainnet.g.alchemy.com/v2/${ALCHEMY_API_KEY}`,
    chainId: 10,
    chainIdOx: "0xa",
    redemptionContract: "0xc9FFaCbdbE074BfEC120A89Cd459DbE218143713",
  },
  /*{
    name: "bsc",
    displayName: "Binance Smart Chain",
    chainName: "Binance Smart Chain Mainnet",
    nativeCurrencyName: "BNB",
    symbol: "",
    logo: bsc,
    rpcUri:
      "https://thrilling-burned-spree.bsc.quiknode.pro/d02a6366667bf70cb137c2a6d11b314014451fec/",
    publicRpcUri: "https://bsc.publicnode.com",
    explorerUri: "https://bscscan.com",
    graphUri: "",
    alchemy: `https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_API_KEY}`, //REPLACE
    chainId: 56,
    chainIdOx: "0x38",
    redemptionContract: "",
  },*/
  {
    name: "avalanche",
    displayName: "Avalanche",
    chainName: "Avalanche C-Chain",
    nativeCurrencyName: "AVAX",
    symbol: "AVAX",
    logo: avalanche,
    rpcUri:
      "https://quiet-shy-mound.avalanche-mainnet.quiknode.pro/52cb9cd981cd085338568721ccceaf4d902ff89c/ext/bc/C/rpc/",
    publicRpcUri: "https://avalanche-c-chain.publicnode.com",
    explorerUri: "https://snowtrace.io",
    graphUri:
      "https://api.studio.thegraph.com/query/33416/erc-20-redemptions-avalanche/version/latest",
    alchemy: `https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_API_KEY}`, //REPLACE
    chainId: 43114,
    chainIdOx: "0xa86a",
    redemptionContract: "0xA68D24005d4Dc631774C8f965dE58E0f014f5977",
  },
];

export const getTokenData = async (network: any, tokenAddress: any) => {
  try {
    const newWeb3 = new Web3(network?.publicRpcUri);
    const instanceERC20 = new newWeb3.eth.Contract(ERC20.abi as any[], tokenAddress);

    // const decimals = await instanceERC20.methods.decimals().call();

    const tokenData = await Promise.all([
      instanceERC20.methods.symbol().call(),
      instanceERC20.methods.name().call(),
      instanceERC20.methods.decimals().call(),
    ]);

    return tokenData;
  } catch (error) {
    console.log(error);
    return ["", "", "18"];
  }
};


export const getTokenListed = async (projectId: String | undefined, network: any) => {
  try {
    const response = await fetch(network?.graphUri, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: `
          query ($projectId: String!) {
            redemptions(where: { projectId: $projectId },orderBy: timestamp, orderDirection: desc) {
              id
              redemptionId
              projectId
              creator
              name
              tokenAmount
              points
              actionName
              scoreType
              contractAddress
              limitOnePerWallet
              timestamp
              redemptionStatus
              poolBalance
              totalRedeems
              uniqueRedeems
            }
          }
        `,
        variables: {
          projectId
        },
      }),
    });

    const { data } = await response.json();

    const filteredRedemptions = data.redemptions.filter((redemption: any) => redemption.redemptionStatus === "Active");

    return filteredRedemptions;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const we3Divide = (amount: number, dec: number) => {
  let poolBalanceFormatted = ethers.utils.formatUnits(amount.toString(), dec);
  return parseFloat(poolBalanceFormatted).toFixed(5);
}

export const getTokenMetadata = async (network: WatcherNetwork, contractAddress: string) => {
  const payload = {
    id: 1,
    jsonrpc: "2.0",
    method: "alchemy_getTokenMetadata",
    params: [contractAddress],
  };

  try {
    const response = await fetch(network.alchemy, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const { result } = await response.json();
    return result
  } catch (error) {
    console.error(error);
    return {}
  }
};

export const getErc20 = async (tokenAddress: string) => {
  try {
    const web3 = await getWeb3();

    const instance = new web3.eth.Contract(
      erc20RedemptionABI.abi,
      tokenAddress
    );
    return instance

  } catch (error) {
    console.error(error);
  }
};