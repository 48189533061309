import "./XpClaim.css";

import { useEffect, useState } from "react";
import { useAddress, useSDK } from "@thirdweb-dev/react";
import { useParams } from "react-router-dom";
import Card from "../../components/Card/Card";
import MenuBar from "../../components/MenuBar/MenuBar";
import StarBG from "../../components/StarBG/StarBG";
import { projectRealtimeDb } from "../../firebase/config";
import hash from "hash.js";
import Modal from "../../components/Modal/Modal";
import JSConfetti from 'js-confetti'
import Lottie from "lottie-react";
import CountUp from 'react-countup';
import graphSingletons from "../../graph/graphSingletons";
import complete from '../../images/complete.json'
import goldStarAnimation from '../../images/goldStar.json'
import avatar from '../../images/avatar3.png'
import defaultbg from '../../images/defaultbg.png'

const jsConfetti = new JSConfetti()

const XpClaim = () => {
  const sdk = useSDK();
  const address = useAddress();

  const { claimId } = useParams();
  let testId = claimId
  // let testId = "b05d50cd-5869-433a-85b7-adddb73b71e8";

  const [updateProfileOpen, setUpdateProfileOpen] = useState(false);
  const [claimData, setClaimData] = useState<any>({});
  const [claimExpire, setClaimExpire] = useState(null);
  const [claimName, setClaimName] = useState(null);
  const [claimQr, setClaimQr] = useState(null);
  const [claimDescription, setClaimDescription] = useState("");
  const [btnStatus, setBtnStatus] = useState("");
  const [logoURL, setLogoUrl] = useState<any>(avatar);
  const [backgroundUrl, setBackgroundUrl] = useState<any>(defaultbg);
  const [projectName, setProjectName] = useState<any>('');
  const [error, setError] = useState<any>(null);
  const [modalShow, setModalShow] = useState<any>(false);
  const [claimed, setClaimed] = useState(false);
  const [currentUserScore, setCurrentUserScore] = useState(0)
  const [page, setPage] = useState<any>('');
  const [claimDataPoints, setClaimDataPoints] = useState<any>(0);
  const [confettiPop, setConfettiPop] = useState<any>('');


  useEffect(() => {
    document.title = 'Xp Claim'
    getClaimData();
  }, [address]);


  useEffect(() => {
    if (address && claimData) {
      checkIfUpdateIdExists();
    }
    setError(null);
    setBtnStatus('')
  }, [address]);

  useEffect(() => {
    if (confettiPop) {
      jsConfetti.addConfetti({

        emojis: ['🦄', '💥', '🎉 '],

        emojiSize: 50,
        confettiNumber: 100,
      })
    }
  }, [confettiPop])

  const getClaimData = async () => {
    let body = {
      claimId: testId,
    };

    let bodyString = JSON.stringify(body);
    try {
      const res = await fetch(
        "https://us-central1-huddl-in.cloudfunctions.net/getXPClaim",
        {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
          }),
          body: bodyString,
        }
      );

      let response = await res.json();
      const { claimData } = response;

      if (!claimData?.xpProjectId) {
        setBtnStatus('close');
        setClaimDescription('Claim is does not exist')
        // setError('Claim is does not exist')
        return
      }

      const { name, qrCodeURL, xpExpire, description, xpProjectId, xpAction, xpScoreType, xpClaimId, points } =
        claimData;

      setClaimDataPoints(points)
      setClaimData(claimData)

      getProjectDetails(xpProjectId)
      setClaimExpire(xpExpire);
      setClaimName(name);
      setClaimQr(qrCodeURL);
      setClaimDescription(description);

    } catch (error) {
      console.log("err: " + error);
    }
  };

  const checkIfUpdateIdExists = async () => {
    if (!claimData?.xpProjectId || !address) return
    setError(null);
    setBtnStatus('loading')
    try {
      const { xpProjectId, xpAction, xpScoreType, xpClaimId, xpExpire, creationDate } =
        claimData;
      const userScore = await graphSingletons.getUserScore(address, 'social', '0x3feb5a90a61ed8c2674da9365c83fbc4cf4c168629129825454a9b6b42bf5383');

      if (Date.now() / 1000 > xpExpire || !xpExpire) {
        setBtnStatus('close');
        setError('Claim is expired')
        return
      }
      setCurrentUserScore(userScore)
      const updateId =
        `${xpProjectId}:${xpAction}:${xpScoreType}:${xpClaimId}:${address}`.toLowerCase();

      const updateIdHash = hash
        .sha256()
        .update(updateId)
        .digest("hex");

      let { data } = await fetch('https://api.thegraph.com/subgraphs/name/bcaklovi/huddln-xp-mainnet', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `
        query($id:String) {
          scoreUpdates(where: {id: $id}) {
            id
            pointChange
            scoreType
            address
            actionName
            createdAt
          }
        }
        `,
          variables: {
            id: '0x' + updateIdHash,
          },
        }),
      }).then((resp) => resp.json());

      if (data.scoreUpdates.length > 0) {
        //score update exists
        setError('XP already claimed')
        setBtnStatus('close')
      }
      else {
        setBtnStatus('')
      }
    } catch (err) {
      setBtnStatus('close');
      setError('Claim is does not exist')
      setBtnStatus('')
    }
  }

  const getProjectDetails = async (projectId: any) => {
    if (projectId)
      await projectRealtimeDb
        .ref()
        .child("xp")
        .child("communities")
        .child(projectId)
        .once("value")
        .then((snapshot) => {
          if (snapshot.exists()) {
            const { imageURI, backgroundImageURI, name } = snapshot.val()
            if (imageURI) setLogoUrl(imageURI);
            if (backgroundImageURI) setBackgroundUrl(backgroundImageURI);
            if (setProjectName) setProjectName(name);

          }
        })
        .catch((err) => {
          console.log(err);
        });
  };



  const claimXpHandler = async () => {
    if (btnStatus === "close" || page === "success") {
      return window.close()
    }
    setError(null);
    setBtnStatus('loading')
    if (!address) {
      setError('Please connect your wallet by pressing "Connect Wallet".');
      setBtnStatus('')
      return;
    }
    try {
      if (claimData?.xpClaimId) {
        let body: any = {
          claimData: {
            claimId: claimData?.xpClaimId
          }
        }

        const message = hash
          .sha256()
          .update(JSON.stringify(body))
          .digest("hex");

        const signature = await sdk?.wallet.sign(message);

        body = {
          claimData: {
            claimId: claimData?.xpClaimId
          },
          verification: {
            signature,
            message,
            address
          }
        }

        let response = await fetch(
          "https://us-central1-huddl-in.cloudfunctions.net/redeemXPClaim",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
          }
        );
        let responeBody = await response.json();
        const { reason } = responeBody


        if (reason === "claim is expired") {
          setBtnStatus('close');
          setError('Claim is expired')
        } else if (reason === "error") {
          setBtnStatus('close');
          setError('Something went wrong')
        } else {
          setPage('success');
          setBtnStatus('')
        }
      }
    } catch (error) {
      setBtnStatus('close');
      setError('Something went wrong')
    }
  };

  return (
    <div className="xpClaim-container">
      <StarBG />
      {/* <div className="main-container"> */}
      <Card>
        {/* <h1 className="xpClaim-title">Claim Your XP</h1> */}
        <div className="xpClaim-top">
          <img
            className="xp-background-image"
            src={backgroundUrl}
            alt="new"
          />
          <div className='xpClaim-content'>
            <div className="xpClaim-userInfo">
              <div className="xpClaim-userImage-boarder">
                <img
                  className="xpClaim-userImage"
                  src={logoURL}
                  alt="new"
                />
              </div>
              <h1 className="xpClaim-username">{projectName}</h1>
            </div>
            {claimQr &&
              <div className="xpClaim-qr" onClick={() => setModalShow(true)}>
                <div className="xpClaim-qrGradient">
                  <img className="xpClaim-qrBarcode" src={claimQr} alt="new" />
                </div>
             
              </div>}
          </div>
        </div>
        {page === "success" ?
          <>
            <Lottie
              animationData={complete}
              loop={false}
              style={{
                height: 100,
                filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5))',
              }}
            />
            <div className="discord-headline">
              Successfully claimed!
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 14 }}>
              <div className="your-xp">Your new XP: &nbsp;</div>
              <CountUp className='gradient-earn-amount'
                start={currentUserScore}
                end={Number(currentUserScore) + Number(claimDataPoints)}
                duration={6}
                separator=","
                useEasing
                prefix=""
                onEnd={() => {
                  setConfettiPop(true);
                }}
                suffix=" XP" />
            </div>
          </>
          : (
            <>
              <div className="title gradient-earn-amount" style={{ fontSize: 18 }}>Claim Your XP</div>
              <div className="xpClaim-info">
                <h1 className="xpClaim-name">{claimName}</h1>
                <h1 className="xpClaim-description">{claimDescription}</h1>
              </div>
              {!!claimDataPoints && //enabled and has not already redeemed reward
                <div style={{ marginTop: '.5em', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                  <div className='crop-anim' style={{ height: 30, width: 30, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>

                    <Lottie
                      animationData={goldStarAnimation}
                      loop={true}

                      style={{

                        width: 72,

                        filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5))',
                      }}
                    />
                  </div>
                  <div className="gradient-earn-amount">Earn: {claimDataPoints}XP</div>
                  <span style={{ fontFamily: 'SFProBold', fontSize: '14px', color: 'white' }}>&nbsp; for claiming</span>
                </div>
              }
            </>
          )
        }
        {error && (
          <div className="error-message">{error}</div>
        )}
        <div className="buttons-box">
          <button
            className={btnStatus === "close" || page === "success" ? "close-window-button" : "connect-discord-button"}
            onClick={claimXpHandler}
            disabled={btnStatus === "loading"}
          >
            {btnStatus === "loading" ? (
              <div className="xpClaim-loader"></div>
            ) : btnStatus === "close" || page === "success" ? (
              <h1 className="xpClaim-button-title close">Close</h1>
            ) : (
              <h1 className="xpClaim-button-title">Claim XP</h1>
            )}
          </button>
        </div>
      </Card>
      {/* </div> */}
      {claimQr && <Modal setShow={setModalShow} show={modalShow}>
        {/* <div className="xpClaim-qr"> */}
        <div className="xpClaim-qrGradient-modal">
          <img className="xpClaim-qrBarcode-modal" src={claimQr} alt="new" />
        </div>
        {/* </div> */}
      </Modal>}
    </div>
  );
};

export default XpClaim;
