import 'react-toastify/dist/ReactToastify.css';

import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import missingToken from '../../images/missingToken.png'
import TokenCard from "../../components/TokenCard/TokenCard";
import Lottie from "lottie-react";
import bonfire from "../../images/bonfire.json";
import errorLottie from '../../images/error.json'
import Pagination from "../../components/Pagination/Pagination";
import { projectRealtimeDb } from "../../firebase/config";
import { Web3Context } from "../../context/Web3Context";
import { capitalize, truncate } from '../../functions/myFunctions'
import { useAddress, useNetworkMismatch, useNetwork, useSDK } from "@thirdweb-dev/react";
import { useApolloClient } from "@apollo/client";
import { GET_TOTAL_SCORE, GET_ROWS_USER } from "../../queries";
import { ToastContainer, toast } from 'react-toastify';
import shining from '../../images/shining.json'
import checkmark from '../../images/Checkmark_anim.json'
import { ImSpinner8 } from "react-icons/im";
import getWeb3 from "../../web3/getWeb3";
import erc20RedemptionABI from "../../abi/ERC20Redemption.json";
import { SUPPORTED_WATCHER_NETWORKS, getTokenListed, getTokenData, we3Divide, getTokenMetadata, getErc20 } from '../../config/config'
import { BsClipboard } from "react-icons/bs";
import ScoreBanner from '../../components/ScoreBanner/ScoreBanner';
import RedemptionBtn from "../../components/RedemptionBtn/RedemptionBtn";


type TokenProps = {
  item: any;
  objectFit?: string
}

const TokenDisplay = ({ item, objectFit }: TokenProps) => {
  // const { logo } = item
  // console.log(item)
  return (
    <div className="flex flex-1 flex-col h-full w-full p-10">
      <div className="w-full flex justify-center items-center h-[250px]">
        <img src={item?.logo ?? missingToken} alt="" className="rounded-2xl shadow-md" style={{
          maxWidth: '80%',
          maxHeight: '80%',
          objectFit: 'contain',
        }} />
      </div>
      <div>
        {Boolean(item?.tokenName) && (
          <>
            <h2 className="font-heading text-grayTxt text-xs font-sans font-normal font-rounded font-medium leading-normal">Token Name</h2>
            <h3 className="font-body text-white text-s font-sans font-normal font-rounded font-extrabold leading-normal">{item?.tokenName}</h3>
          </>)}
        {Boolean(item?.symbol) && (
          <>
            <h2 className="font-heading text-grayTxt text-xs font-sans font-normal font-rounded font-medium leading-normal">Token Symbol</h2>
            <h3 className="font-body text-white text-s font-sans font-normal font-rounded font-extrabold leading-normal">{item?.symbol}</h3>

          </>)}
      </div>
    </div>

  )
}

export default function TokenRedemption() {
  const address = useAddress();
  const isMismatched = useNetworkMismatch();
  const [{ error, loading }, switchNetwork] = useNetwork();
  const sdk = useSDK()
  const { projectId, chainId, redemptionId } = useParams();
  const {
    purchase1155,
    earn1155,
    purchase721,
    earn721
  } = useContext(Web3Context);
  const client = useApolloClient();
  const navigate = useNavigate();

  const [redemptionChangeTime, setRedemptionChangeTime] = useState(Date.now());
  const [network, setNetWork] = useState<any>()
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [isRunningMount, setIsRunningMount] = useState(false);
  const [showModal, setShowModal] = useState(false)
  const [currenrRedeemable, setCurrentRedeemable] = useState({})
  const [allRedeemables, setAllReedemables] = useState([])
  const [data, setData] = useState({});
  const [communtyName, setCommunityName] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [initAmount, setInitAmount] = useState(0);
  const [balance, setBalance] = useState(0);
  const [xp, setXp] = useState(0);
  const [scoreType, setScoreType] = useState('');
  const [isPurchaseable, setIsPurchaseable] = useState(false);
  const [alreadyEarned, setAlreadyEarned] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [allXp, setAllXP] = useState('');
  const [tokenType, setTokenType] = useState("");
  const [contractAddress, setContractAddress] = useState("");
  const [src, setSrc] = useState("");
  const [srcType, setSrcType] = useState("");
  const [collectors, setCollectors] = useState([])
  const [tokenData, setTokenData] = useState<any>({})
  const [dynamicHref, setDynamicHref] = useState("/example");
  // btn
  const [progressBar, setProgressBar] = useState(0);
  const [progressBarAnim, setProgressBarAnim] = useState(1);
  const [btnTxt, setBtnTxt] = useState("");
  const [btnState, setBtnState] = useState("");
  const [progressBarComplete, setProgressBarComplete] = useState(false);
  const [userXp, setUserXp] = useState(0);
  const [mediaHover, setMediaHover] = useState(false);
  const [displayXp, setDisplayXp] = useState(['ALL:0 XP'])
  const [timeDone, setTimeDone] = useState(false);
  const [xpCheckFail, setXpCheckFail] = useState(false);

  useEffect(() => {
    const item = SUPPORTED_WATCHER_NETWORKS.find((network) => network?.chainId === Number(chainId));
    setNetWork(item)
  }, [])

  /**
   * ATOMIC MOUNT STATE, update everythign a specific order of dependencies. Helps to organize update and enforce order.
   */
  const mountData = async () => {
    try {
      const n = SUPPORTED_WATCHER_NETWORKS.find((network) => network.chainId === Number(chainId));
      setIsRunningMount(true);

      // RESET DEFAULT STATES
      setUserXp(0);
      setProgressBarComplete(false)
      setProgressBarAnim(.5)
      setProgressBar(0)
      setAlreadyEarned(false)
      setBtnState('')
      setUserXp(0)
      setBtnTxt('Calculating XP...')
      setTimeDone(false)

      //solo done in parallel for performance, this quuery is non blocker to groupA promises
      const items = await getTokenListed(projectId, n)

      const tokenItem = items.find((redemption: any) => redemption.redemptionId === redemptionId);
      const list = items.filter((redemption: any) => redemption.redemptionId !== redemptionId);
      const token = await getTokenDataMain(n, tokenItem)

      setDynamicHref(`${n?.explorerUri}/address/${token?.contractAddress}`);
      setTokenData(token)
      setAllReedemables(list)

      let promiseGroupA = await Promise.allSettled([
        // getListedRedemption(redemptionId),
        getUserXPData(address, projectId),
        hasRedemptionBeenEarned(redemptionId, address)
      ])

      const userXPData = await (promiseGroupA[0] as any).value;
      const hasBeenEarned = (promiseGroupA[1] as any).value;

      userXPData?.totalScore && setDisplayXp(formatXPForDisplay(userXPData?.scores, userXPData?.totalScore));
      await setupButtonState(token, hasBeenEarned, userXPData);
      setIsRunningMount(false)


    } catch (err) {
      setIsRunningMount(false)
      console.log(err)
    }

  }
  /**
   * Force order of remoutns, fixes race condition if you click fast on new item load.
   * @param retry 
   * @returns 
   */
  const isValidURL = async (url: any) => {

    let valid = false;
    if (url == null) return false;
    try {
      await fetch(url).then(async (response) => {
        valid = response.status == 200;

      });


      return valid;
    } catch (e) {

      return false;
    }
  }

  const getTokenDataMain = async (n: any, item: any) => {
    try {
      const { contractAddress, name, poolBalance, tokenAmount } = item
      const result = await getTokenMetadata(network, contractAddress)

      const [symbol, tokenName, decimals] = await getTokenData(n, contractAddress);

      const poolBalanceFormatted = we3Divide(poolBalance, decimals)
      const tokenAmountFormatted = we3Divide(tokenAmount, decimals)
      if (symbol) {
        const iconVersion = "QmPwpDzQP2cFLeekaqjb4C5tK3ukaXGKEvq1gvLjaRkyGj";
        let symbolNormalized = symbol ? symbol.replace(".e", "") : result.symbol //for DAI.e, USDT.e USDC.e etc, use symbol from alchemy or from other api
        symbolNormalized = symbolNormalized.replace("WETH", "ETH"); //spacial caseses
        symbolNormalized = symbolNormalized.replace("WOP", "OP"); //spacial caseses
        symbolNormalized = symbolNormalized.replace("WAVAX", "AVAX"); //spacial caseses
        symbolNormalized = symbolNormalized.replace("WARB", "ARB"); //spacial caseses
        symbolNormalized = symbolNormalized.replace("WMATIC", "MATIC"); //spacial caseses
        const logoCached = `https://huddln.mypinata.cloud/ipfs/${iconVersion}/128/color/${symbolNormalized.toLowerCase()}.png`

        setSrc(missingToken);// set first as fallback, replace with if statements below
        // check for valid urls that point at images
        if (result && await isValidURL(result.logo)) { alert(result.logo); item.logo = result.logo; }
        else if (await isValidURL(logoCached)) item.logo = logoCached;

      }


      return {
        ...item,
        redemptionName: name,
        ...result,
        symbol,
        tokenName,
        decimals,
        poolBalanceFormatted,
        tokenAmountFormatted
      }
    } catch (error) {
      console.error(error);
      return {}
    }
  }


  const forceWaitTryMount = async (retry: any) => {
    if (retry == 5) return;
    if (!isRunningMount) {
      await mountData();
      return;
    }
    else {
      setTimeout(async () => {
        await forceWaitTryMount(retry + 1)
      }, 100)
    }
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  useEffect(() => {
    // if (!isRunningMount) mountData();
    forceWaitTryMount(0);
  }, [redemptionId, address, redemptionChangeTime])

  const getXp = async () => {
    let promiseGroupA = await Promise.allSettled([
      getUserXPData(address, projectId),
      hasRedemptionBeenEarned(redemptionId, address)
    ])

    const userXPData = await (promiseGroupA[0] as any).value;//PARALLEL GROUP GET USER XP DATA ABOUT USER
    const hasBeenEarned = (promiseGroupA[1] as any).value;

    userXPData?.totalScore && setDisplayXp(formatXPForDisplay(userXPData?.scores, userXPData?.totalScore));
    // await setupButtonState(redemptionItem, hasBeenEarned, userXPData);
  }

  useEffect(() => {
    if (xpCheckFail && address) {
      getXp()
    }
  }, [xpCheckFail, address])

  /**
   * Check if the same person who started this listing still have this item in their account, if not they may have listed it in another listing and already sold out.
   * or they may have sold it somewhere else, only way to know if this listing is still good, is if they still have at least one in their account.
   */
  const checkIfListingIsStillValid = (item: any) => {

  }

  /**
   * 
   * @param redemptionItem 
   * @param userXpData 
   */
  const setupButtonState = async (token: any, hasEarned: any, userXpData: any) => {
    if (!address) {
      return
    }

    let timerID;
    // if (isAnimationTimeoutDone) {
    //   clearTimeout(timeoutFunc)
    // }
    clearTimeout(timerID)
    let useXP = 0;
    userXpData?.scores?.forEach((scoreObject: any) => {
      if (scoreObject?.scoreType == token.scoreType) useXP = (scoreObject.points).toString();
    })
    // const useXp = userXpData?.scores.
    //setAllXP(useXP)

    if (!token.requiresPurchase && hasEarned) {
      setAlreadyEarned(true);
      return redeemHandlerBtn('Already Earned', 'earned');
    } else if (token.poolBalance == 0) {
      setAlreadyEarned(true);
      return redeemHandlerBtn('Sold Out!', 'earned');
    }

    setBtnState('')
    const userXpInt = Number(useXP)
    const nftXpInt = Number(token.points)


    const percentAnim = userXpInt >= nftXpInt ? 100 : (userXpInt / nftXpInt) * 100
    setXp(nftXpInt)
    setUserXp(userXpInt)
    setProgressBarAnim(3)
    setProgressBar(percentAnim)

    let t = ''

    if (nftXpInt >= userXpInt) {
      t = `${nftXpInt - userXpInt} XP Needed`
      setBtnTxt(t)
    } else {
      t = isPurchaseable ? `Buy for ${nftXpInt} XP` : `Claim for ${nftXpInt} XP`
      setBtnTxt(t)
    }
    let timeoutFunctionVar: any = null;

    const setTimeoutFunction = () => {
      clearTimeout(timeoutFunctionVar)
      timeoutFunctionVar = setTimeout(() => {
        setProgressBarComplete(true)
        setTimeDone(true)
      }, 3200);
    };
    setTimeoutFunction()
  }

  const formatXPForDisplay = (scoreBoard: any, totalScore: any) => {
    let ar;
    if (scoreBoard?.length > 0) {
      let ar: any = [`ALL:${totalScore}XP`]

      if (scoreBoard?.length > 0) {
        scoreBoard.forEach((element: any) => {

          const txt = `${element.scoreType.toUpperCase()}:${element.points}`;
          ar.push(txt)
        });
      }
      return ar;
    } else {
      return ['ALL:0 XP']
    }
  }

  const getUserXPData = async (address: any, projectId: any) => {
    try {
      const totalScoreResult = await client.query({
        query: GET_TOTAL_SCORE,
        variables: {
          projectId,
          address: address?.toLowerCase(),
        },
      });
      if (totalScoreResult?.data?.scoreboards[0]) {
        setXpCheckFail(false)
        return totalScoreResult?.data?.scoreboards[0];
      } else {
        setXpCheckFail(true)
        return null
      }
    } catch (error) {
      console.log(error)
    }
  }

  const hasRedemptionBeenEarned = async (redemptionId: any, address: any) => {
    try {
      const response = await fetch(network?.graphUri, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `
            query getUserRedeems($redemptionId: String!, $redeemer: String!) {
              redeemeds(where: { redemptionId: $redemptionId, redeemer: $redeemer }) {
                redemptionId
                redeemer
              }
            }
          `,
          variables: {
            redemptionId,
            redeemer: address.toLowerCase()
          },
        }),
      });

      const { data } = await response.json();

      return (data?.redeemeds?.length > 0);
    } catch (error) {
      return false;
    }
  };

  const redeemHandlerBtn = (btnT: string, btnS: string) => {
    setBtnTxt(btnT)
    setBtnState(btnS)
  }

  // const redeemHandler = async (redemptionID: string, networkID: number) => {
  const redeemHandler = async () => {
    const networkID = Number(chainId)
    const redemptionID = redemptionId

    if (!address) {
      toast.error('Connect your wallet to get rewards!');
      return toast.clearWaitingQueue();
    } else if (Number(xp) > Number(userXp)) {
      toast(`Not enough XP in ${tokenData?.scoreType} score type`)
      return toast.clearWaitingQueue();
    }
    try {
      setBtnState('loading')
      if (switchNetwork) await switchNetwork(networkID);

      const web3 = await getWeb3();

      const accounts = await web3.eth.getAccounts();

      let instanceERC20Redemption = await getErc20(network.redemptionContract);

      const signature = await sdk?.wallet.sign("Redeem ERC20");

      await instanceERC20Redemption.methods
        .redeem(redemptionID, signature)
        .send({
          from: accounts[0],
        })
        .once("transactionHash", (hash: any) => {
          console.log("hash: " + hash);
        })
        .on("error", (error: any) => {
          console.log(error);
        });
      setBtnState('success')
    } catch (err) {
      console.log(err);
      setBtnState('error')
    }
  };

  const navToCommunityHandler = () => {
    navigate(
      `/community/${projectId}`
    );
  }

  const navTo = (id: any) => {
    setRedemptionChangeTime(Date.now());//Forces refresh even if you tap on same card.
    navigate(
      `/community/${projectId}/${chainId}/${id}`
    );
  }
  const handleWindowSizeChange = () => setWindowWidth(window.innerWidth);
  const handleMediaHoverLeave = () => setMediaHover(false);
  const handleMediaHoverEnter = () => setMediaHover(true)
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(4);

  const lastPostIndex = currentPage * postPerPage;
  const firstPostIndex = lastPostIndex - postPerPage;
  const shownNfts = allRedeemables.slice(firstPostIndex, lastPostIndex);

  return (
    <div className="community-redemption-container" >
      <meta
        property="og:description"
        key="og:description"
        content={description}
      />
      <meta
        property="og:image"
        key="og:image"
        content={src}
      />

      <div className="community-redemption-container-inner"  >
        <div className="community-redemption-name" >
          <h1 onClick={navToCommunityHandler}>{communtyName ? communtyName : 'Community'}</h1>
        </div>
        <div className="redemption-container-top" >
          <div className="redemption-container-inner" onClick={() => setShowModal(true)}>
            <div className="redemption-container-img"
              onMouseEnter={handleMediaHoverEnter} onMouseLeave={handleMediaHoverLeave} >
              <TokenDisplay
                item={tokenData}
                objectFit={mediaHover ? "contain" : "cover"}
              />
            </div>
          </div>
          <div className="redemption-container-inner">
            <div className="redemption-container-description">
              <div className="redemption-container-score-info">
                <div className="redemption-container-score-info-inner">
                  <h3 className="nft-h3">Token Type</h3>
                  <h2 className="nft-name">ERC-20</h2>
                </div>
                <div className="flex flex-1 flex-col">
                  <h3 className="nft-h3">Network</h3>
                  <div className="flex flex-row bg-bgDarkGray rounded-md items-center max-w-min py-2 pl-3 pr-6">
                    <img src={network?.logo} className="h-[14px] w-[14px] object-contain mr-[8px]" alt='' />
                    <h2 className="text-sm text-white">{network?.displayName}</h2>
                  </div>
                </div>
              </div>
              <div className="redemption-container-score-info">
                <div className="redemption-container-score-info-inner">
                  <h3 className="nft-h3" >Contract Address</h3>
                  <div className="w-full max-h-80 overflow-y-auto overflow-x-hidden mb-15 flex flex-row items-center">
                    <a href={dynamicHref} target="_blank" rel="noreferrer">
                      <h1 className="text-sm text-white truncate max-w-xs hover:scale-100 cursor-pointer">{truncate(tokenData?.contractAddress, 9, "...")}</h1>
                    </a>
                    <BsClipboard style={{ marginLeft: 10 }} />
                  </div>
                </div>
                <div className="flex flex-1 flex-col">
                  <h3 className="nft-h3">Type</h3>
                  <h2 className="nft-name">{tokenData?.limitOnePerWallet ? 'Single-Use' : 'Unlimited'}</h2>
                </div>
              </div>
              <hr className="redemption-hr small" />
              <div className="redemption-container-editions" >
                <h1 style={{ color: 'white' }}>Users Collected: {tokenData?.uniqueRedeems}</h1>
              </div>
              <div className="redemption-container-score-info">
                <div className="redemption-container-score-info-inner">
                  <h3 className="nft-h3">Exchange Rate</h3>
                  <h1 className="nft-name">{tokenData?.points} XP : {tokenData?.tokenAmountFormatted} {tokenData?.symbol}</h1>
                </div>
                <div className="redemption-container-score-info-inner">
                  <h3 className="nft-h3">Pool Balance</h3>
                  <h1 className="nft-name">{tokenData.poolBalanceFormatted}</h1>
                </div>
              </div>
              <div className="redemption-container-score-info">
                <div className="redemption-container-score-info-inner">
                  <h3 className="nft-h3">Collect For</h3>
                  <h1 className="nft-name">{tokenData?.points} XP</h1>
                </div>
                <div className="redemption-container-score-info-inner">
                  <h3 className="nft-h3">Score Type</h3>
                  <h1 className="nft-name">{tokenData.scoreType}</h1>
                </div>
              </div>
              {Boolean(address) &&
                <div className="redeem-handler" >
                  {progressBarComplete && (
                    <Lottie
                      animationData={shining}
                      loop={true}
                      style={{
                        position: 'absolute',
                        flexDirection: 'column',
                        top: -50,
                        left: 65,
                        right: 0,
                        bottom: 0,
                        width: 200,
                        height: 200,
                        filter: 'drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.2))',
                      }}
                    />
                  )}
                  <RedemptionBtn
                    alreadyEarned={alreadyEarned}
                    btnTxt={btnTxt}
                    btnState={btnState}
                    isPurchaseable={isPurchaseable}
                    redeemHandler={redeemHandler}
                    timeDone={timeDone}
                    progressBarAnim={progressBarAnim}
                    progressBar={progressBar}
                  />
                </div>}
            </div>
          </div>
        </div>
        <ScoreBanner address={address} displayXp={displayXp} />
        <hr className="redemption-hr" />
        <div className="other-redemption-container">
          <div className="other-redemption-container-title">
            <h1>Other Redemptions from this Community</h1>
          </div>
          {shownNfts.length > 0 ? (
            <div className="other-redemption-container-map" >
              {shownNfts.map((item: any) =>
                <TokenCard
                  item={item}
                  key={item?.id}
                  onClick={navTo}
                  width='180px'
                  style={{
                    marginBottom: 30
                  }}
                  network={network}
                  padding="10px"
                />
              )}
            </div>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Lottie
                animationData={bonfire}
                loop={true}
                style={{ height: 300 }}
              />
            </div>
          )}
          <Pagination
            total={allRedeemables.length}
            perPage={postPerPage}
            setPage={setCurrentPage}
            currentPage={currentPage}
            enableEnd={false}
            onEndReach={() => { }}
          />
        </div>
      </div>
      {
        showModal && <div onClick={() => setShowModal(false)} style={{ position: 'fixed', display: 'flex', top: 0, left: 0, bottom: 0, right: 0, background: 'rgba(0,0,0,.9)', height: '100%', width: '100%', justifyContent: "center", alignItems: 'center', zIndex: 1000000 }}>
          <div style={{ borderRadius: '10px', overflow: "hidden", objectFit: 'contain' }}>
            <TokenDisplay
              item={tokenData}
              objectFit='contain'
            />
          </div>
        </div>
      }
      <ToastContainer
        limit={3}
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div >
  )
}
