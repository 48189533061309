import "./Home.css";

import { useSelector } from "react-redux";
import { RootState } from "../../redux/Store";
import { useAddress } from "@thirdweb-dev/react";
import { useEffect, useState } from "react"
import { getCommunitiesFb } from "../../singletons/Firebase"
import { useNavigate } from "react-router-dom";
import HomeCard from "./HomeCard";
import DropDown from "../../components/DropDown/DropDown";
import featuredStar from "../../images/featured-star.png";
import FeaturedCard from "./FeaturedCard";
import Lottie from "lottie-react";

import Star from "../../images/star.png";
import Flame from "../../images/flame.png";
import verified from '../../images/purple-check.png'
import myHome from '../../images/myHome.png'
import myHand from '../../images/myHand.png'
import connectLost from '../../images/connection-lost.json'
import HeroCard from "./HeroCard";

const FEATURED_XP_ID = '0x130e1b27ec9f526f769bd5cfb3ac3b66421470a139f6111788f5537901464492'
const FILTER_LIST: any = [
  { name: 'Newest', logo: Star },
  { name: 'Trending', logo: Flame }
]


export default function Home() {
  const navigate = useNavigate();
  const address = useAddress();

  const [featured, setFeatured] = useState<any>(null)
  const [filteredCommunities, setFilteredCommunities] = useState<any>([])
  const [selectedFilter, setSelectedFilter] = useState<any>(null)

  const { allVerifiedCommunities, projectShowType, userCommunities, userProjects } = useSelector((state: RootState) => state.community);


  useEffect(() => {
    document.title = 'Home';

  }, [])

  useEffect(() => {
    getFeatured();
  }, [allVerifiedCommunities])


  useEffect(() => {
    filterCommunities()
  }, [allVerifiedCommunities, projectShowType, userCommunities, userProjects])

  const getFeatured = async () => {
    // const filterFeature = allVerifiedCommunities.find((c: any) => c.id === FEATURED_XP_ID);
    // console.log('featured',filterFeature)
    // setFeatured(filterFeature)
  }

  const navToCommunity = (projectId: any) => {
    navigate(
      `/community/${projectId}`
    );
  }


  const filterCommunities = () => {
    let filteredResult: any = []
    if (projectShowType === 'community') {
      const array = userCommunities?.scoreboards
      let result: any = []
      allVerifiedCommunities?.forEach((all: any) => {
        array?.forEach((element: any) => {
          if (all.xpId === element.project.id) {
            result.push(all)
          }
        });
      });
      setFilteredCommunities(result)
    } else if (projectShowType === 'project') {
      const array = userProjects?.projects
      let result: any = []

      allVerifiedCommunities?.forEach((all: any) => {
        array?.forEach((element: any) => {
          if (all.xpId === element.id) {
            result.push(all)
          }
        });
      });
      setFilteredCommunities(result)
    } else {
      filteredResult = allVerifiedCommunities.filter((c: any) => c.verification === '1');
      setFilteredCommunities(filteredResult)
    }

  }

  const setSelectedFilterHandler = (item: any) => {
    const { name } = item

    let sorted
    if (name === 'Newest') {
      sorted = filteredCommunities.sort((a: any, b: any) => a.createdAt > b.createdAt ? -1 : 1)
      setFilteredCommunities(sorted)
    } else {
      sorted = filteredCommunities.sort((a: any, b: any) => a.userIncreasePercent > b.userIncreasePercent ? -1 : 1)

      setFilteredCommunities(sorted)
    }
    setSelectedFilter(item)
  }

  return (
    <div className="home-container-outer">
      <div className="home-container">
        <section className="home-container-header">
          <h1>Discover</h1>
        </section>
        {//featured &&
          <div>
            <div className="home-container-header-lower">
              <div className="home-container-header-lower-left">
                <img src={featuredStar} alt="verified" className="h-6 w-6 object-contain" />
                <p style={{color:"white"}}>XP</p>
              </div>
            </div>
            <HeroCard />
          </div>
        }
        <div className="home-container-header-lower">
          <div className="home-container-header-lower-left">
            <img
              src={projectShowType === 'community' ? myHome : projectShowType === 'project' ? myHand : verified}
              alt="verified"
              className="h-6 w-6 object-contain" />
            <p style={{color:"white"}}>{projectShowType === 'community' ? 'My Communities' : projectShowType === 'project' ? 'My Projects' : 'Verified Communities'}</p>
          </div>
          <DropDown
            currentItem={selectedFilter}
            setCurrentItem={setSelectedFilterHandler}
            list={FILTER_LIST}
          />
        </div>
        <div className="home-horizontal-line" />
        <section className="home-container-inner">
          {filteredCommunities.length > 0 && filteredCommunities?.map((item: any) => {
            return (
              <HomeCard item={item} key={item.xpId} navToCommunity={navToCommunity} />
            )
          })}
        </section>
        {!address && (projectShowType === 'community' || projectShowType === 'project') && (
          <div className="flex flex-col pt-20 items-center text-center h-220 overflow-hidden w-full">
            <Lottie
              animationData={connectLost}
              loop={true}
              style={{
                height: 130,
                marginBottom: 20,
                filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5))',
                alignSelf: 'center'
              }}
            />
            <h2>
              {
                `Connect your wallet to see the ${projectShowType === 'community' ? 'communities you’ve received XP from!' : 'projects you own!'}`
              }
            </h2>
          </div>
        )}
      </div >
    </div >
  )
}
