import { useEffect, useState } from 'react'
import missingToken from '../../images/missingToken.png'
import { we3Divide, getTokenMetadata, getTokenData } from '../../config/config';
import { truncate } from '../../functions/myFunctions';

type Props = {
  item: any;
  style: any;
  width: string;
  onClick: (id: number) => void;
  padding?: string
  network: any
}

export default function TokenCard({ item, network, style, width, onClick, padding }: Props) {
  const [data, setData] = useState<any>({});
  const [src, setSrc] = useState("");
  const [mounted, setMounted] = useState(false);

  const isValidURL = async (url: any) => {
    let valid = false;
    if (url == null) return false;
    try {
      await fetch(url).then(async (response) => {

        valid = response.status == 200;

      });

      return valid;
    } catch (e) {
      return false;
    }
  }

  useEffect(() => {
    const getNftData = async () => {
      setMounted(false)
      const { contractAddress, name, poolBalance, tokenAmount } = item
      const result = await getTokenMetadata(network, contractAddress)
      const [symbol, tokenName, decimals] = await getTokenData(network, contractAddress);
      const poolBalanceFormatted = we3Divide(poolBalance, decimals)
      if (symbol) {
        const iconVersion = "QmPwpDzQP2cFLeekaqjb4C5tK3ukaXGKEvq1gvLjaRkyGj";
        let symbolNormalized = symbol ? symbol.replace(".e", "") : result.symbol //for DAI.e, USDT.e USDC.e etc, use symbol from alchemy or from other api
        symbolNormalized = symbolNormalized.replace("WETH", "ETH"); //spacial caseses
        symbolNormalized = symbolNormalized.replace("WOP", "OP"); //spacial caseses
        symbolNormalized = symbolNormalized.replace("WAVAX", "AVAX"); //spacial caseses
        symbolNormalized = symbolNormalized.replace("WARB", "ARB"); //spacial caseses
        symbolNormalized = symbolNormalized.replace("WMATIC", "MATIC"); //spacial caseses
        const logoCached = `https://huddln.mypinata.cloud/ipfs/${iconVersion}/128/color/${symbolNormalized.toLowerCase()}.png`

        setSrc(missingToken);// set first as fallback, replace with if statements below
        // check for valid urls that point at images
        if (result && await isValidURL(result.logo)) setSrc(result.logo);
        else if (await isValidURL(logoCached)) setSrc(logoCached);

      }


      setData({
        ...item,
        ...result,
        symbol,
        poolBalanceFormatted
      })
      setMounted(true)

    };


    getNftData();
  }, []);


  return (
    <div className="redemption-card-outer" style={{ opacity: mounted ? 1 : 0, ...style }} onClick={() => onClick(item?.redemptionId)}>
      <div className='redemption-card' style={{ width, }}  >
        <div className='redemption-card-img-contain'>
          {/* <DisplayNftType
            type='png'
            src={missingToken}
            height={width}
            width={width}
            borderRadius='10px'
            loaded={loaded}
          /> */}
          <div style={{
            width,
            height: width,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>

            <img
              key={src}
              style={{
                maxWidth: '80%',
                maxHeight: '80%',
                objectFit: 'contain',
              }}
              src={src ?? missingToken}

              alt=""
            />
          </div>
        </div>
        <div className='' style={{ padding: padding ? padding : '20px' }}>
          <h1 className='text-lg text-white m-0 truncate'>{item?.name ? item?.name : data?.contractAddress}</h1>
          <h3 className='text-sm text-white m-0'>{data?.limitOnePerWallet ? 'One Per ' : 'Unlimited'}</h3>
          <h2 className='text-sm text-gray-400 truncate'>{data?.symbol} ({truncate(data?.contractAddress, 11, "...")})</h2>
          <h4 className='text-sm text-gray-400 truncate' >
            {data?.redemptionStatus}
          </h4>
          <h4 className='text-sm text-gray-400 truncate' >
            Pool Balance: {data?.poolBalanceFormatted}
          </h4>
        </div>
      </div>
    </div>
  )
};