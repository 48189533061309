import "./MenuBar.css";
import { useState, useEffect } from "react";
import { ConnectWallet, useAddress, useNetworkMismatch, useNetwork } from "@thirdweb-dev/react";
import { useNavigate } from "react-router-dom";
import { IoMdMenu } from "react-icons/io";
import xpLogo from "../../images/xp3.png";
import {
  InstantSearch,
  SearchBox,
  Hits,
  Configure,
} from "react-instantsearch-hooks-web";
import { useProSidebar } from "react-pro-sidebar";
import algoliasearch from "algoliasearch";
import avatar3 from "../../images/avatar3.png";
import avatar4 from "../../images/avatar4.png";
import avatar5 from "../../images/avatar5.png";
import avatar6 from "../../images/avatar6.png";
import avatar7 from "../../images/avatar7.png";
import UserAvatar from "../UserAvatar/UserAvatar";
import { ToastContainer, toast } from 'react-toastify';

const ALGOLIA = {
  appId: "VEZHZ5YNG5",
  apiKey: "1e61b997c8f67306166c50b1990cdf0b",
};

const Algolia_Client = algoliasearch(ALGOLIA.appId, ALGOLIA.apiKey);

const Hit = ({ hit, navToUser }: any) => {
  const { userImageURI, userName, objectID } = hit;

  const randNum = Math.floor(Math.random() * 7) + 3;
  const randImg =
    randNum === 3
      ? avatar3
      : randNum === 4
        ? avatar4
        : randNum === 5
          ? avatar5
          : randNum === 6
            ? avatar6
            : avatar7;
  return (
    <div className="flex flex-row items-center h-[40px]" onClick={() => navToUser(objectID.toLowerCase())}>
      <div className="w-[25px] h-[25px] bg-white flex justify-center items-center rounded-full shadow-md mr-5">
        <img src={userImageURI ? userImageURI : randImg} alt="" style={{ height: 22, width: 22, borderRadius: 11 }} />
      </div>
      {userName ? (
        <>
          {" "}
          <h5 className="hit-user-name">@{userName}</h5>{" "}
          <h5 className="hit-user-id">{objectID}</h5>{" "}
        </>
      ) : (
        <h5 className="hit-user-id">{objectID}</h5>
      )}
    </div>
  );
};

interface Props {
  setOpenEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

const MenuBar = ({ setOpenEdit }: Props) => {
  const address = useAddress();
  const isMismatched = useNetworkMismatch();
  const [{ data, error, loading }, switchNetwork] = useNetwork();

  useEffect(() => {
    // const switchFun = async () => {
    //   if (!switchNetwork) {
    //     console.log("can not switch network");
    //     return;
    //   }

    //   const result = await switchNetwork(137);
    //   if (result.data) {
    //     console.log("Switched to Mumbai Polygon successfully");
    //   } else {
    //     toast.error('Please connect to Polygon Network');
    //     console.log("Error switching to Polygon testnet", result.error);
    //   }
    // }
    // if (isMismatched && address) {
    //   switchFun()
    // }
  }, [address, isMismatched])


  const navigate = useNavigate();
  const [showHits, setShowHits] = useState(false);
  const [openBubble, setOpenBubble] = useState(false);

  const { collapseSidebar, toggleSidebar, broken, rtl } = useProSidebar();

  const toggle = () => {
    toggleSidebar();
  };

  const navToHome = (a: any) => {
    navigate(`/`);
  };

  const navToUser = (a: any) => {
    navigate(`/user/${a.toLowerCase()}`);
  };

  const blurHits = () => {
    setTimeout(() => {
      setShowHits(false);
    }, 250);
  };

  const toggleMenuBubble = () => {
    setOpenBubble((v) => !v);
  };

  const viewProfile = () => {
    navToUser(address);
    setOpenBubble(false);
  };

  const editProfile = () => {
    setOpenEdit(true);
    setOpenBubble(false);
  };

  return (
    <div className="menu-bar-contain">
      <div className="menu-bar">
        <div className="menu-bar-left" onClick={navToHome}>
          <img src={xpLogo} style={{ objectFit: "contain" }} />
        </div>
        <InstantSearch indexName="users_public" searchClient={Algolia_Client}>
          <Configure
            analytics={false}
            // filters="free_shipping:true"
            hitsPerPage={5}
          />
          <div className="search-box">
            <SearchBox onFocus={() => setShowHits(true)} onBlur={blurHits} />
            {showHits && (
              <Hits
                hitComponent={({ hit }) => (
                  <Hit hit={hit} navToUser={navToUser} />
                )}
              />
            )}
          </div>
        </InstantSearch>
        <div
          className="menu-bar-right"
          style={{ WebkitFontSmoothing: "subpixel-antialiased" }}
        >
          {/* <MdSearch /> */}
          {/* <div className="connect-wallet"> */}
          <ConnectWallet
            btnTitle="Connect"
            style={{
              backgroundColor: '#323232',
              paddingRight: "18px",
              paddingLeft: "14px",
              paddingTop: "4px",
              paddingBottom: "4px",
              marginRight: 10,
              minHeight: "40px",
              borderRadius: '30px',
              borderWidth: 0,
              boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.75)',
              color: 'white'

            }}
            className="wallet-button"
          />
          {/* </div> */}
          {address && (
            <div style={{ position: "relative" }}>
              <UserAvatar
                address={address}
                withVerification={false}
                onClick={toggleMenuBubble}
              />
              {openBubble && (
                <div className="avatar-bubble">
                  <div className="avatar-bubble-div">
                    <h4 style={{ fontSize: 16 }} onClick={viewProfile}>
                      View Profile
                    </h4>
                  </div>
                  <div className="avatar-bubble-div" onClick={editProfile}>
                    <h4 style={{ fontSize: 16 }}>Edit Profile</h4>
                  </div>
                </div>
              )}
            </div>
          )}
          {broken && (
            <IoMdMenu
              color="white"
              onClick={toggle}
              size={32}
              style={{
                marginRight: 10,
                marginLeft: 10,
                cursor: "pointer",
              }}
            />
          )}
        </div>
      </div>
      <ToastContainer
        limit={3}
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default MenuBar;
