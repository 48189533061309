import { useContract } from "@thirdweb-dev/react";
import { useEffect, useState } from "react";
import { useSDK } from "@thirdweb-dev/react";

import redemptions721ABI from "../abi/RedemptionsFunctionsExisting721.json";
import redemptions1155ABI from "../abi/RedemptionsFunctions.json";

import { SmartContract } from "@thirdweb-dev/sdk";
import { BaseContract, BigNumber, utils } from "ethers";
import { ApolloClient, InMemoryCache, useApolloClient } from "@apollo/client";
import { GET_PROJECT_REDEEMABLES } from "../queries";

const Redeemables = () => {
  const sdk = useSDK();

  const redeemableClient = new ApolloClient({
    uri: "https://api.thegraph.com/subgraphs/name/bcaklovi/huddln-redemptions-matic-v1",
    cache: new InMemoryCache(),
  });


  const [redeemables1155Contract, setRedeemables1155Contract] = useState<
    SmartContract<BaseContract> | undefined
  >();
  const [redeemables721Contract, setRedeemables721Contract] = useState<
    SmartContract<BaseContract> | undefined
  >();

  const purchase1155 = async (redeemableID: string, projectID: string) => {
    if (redeemables1155Contract) {
      let randomUpdateID = BigNumber.from(utils.randomBytes(32))._hex;

      const tx = await redeemables1155Contract.prepare("purchaseRedeemable", [
        randomUpdateID,
        redeemableID,
        projectID,
      ]);
      
      tx.setGaslessOptions({
        biconomy: {
          apiKey: "DZ5cVN1Oa.91230c39-f142-460d-bd74-aaf0ea6e52b2",
          apiId: "0fe0b145-2131-44ef-a9cc-9524480237b3",
          deadlineSeconds: 60,
        },
      });

      tx.send();
    }
  };

  const earn1155 = async (redeemableID: string, projectID: string) => {
    if (redeemables1155Contract) {
      const tx = await redeemables1155Contract.prepare("earnRedeemable", [
        redeemableID,
        projectID,
      ]);

      tx.setGaslessOptions({
        biconomy: {
          apiKey: "DZ5cVN1Oa.91230c39-f142-460d-bd74-aaf0ea6e52b2",
          apiId: "0a42ce14-62c9-487f-8652-9ab4020b970d",
          deadlineSeconds: 60,
        },
      });

      tx.send();
    }
  };

  const purchase721 = async (redeemableID: string, projectID: string) => {
    if (redeemables721Contract) {
      let randomUpdateID = BigNumber.from(utils.randomBytes(32))._hex;

      const tx = await redeemables721Contract.prepare("purchaseRedeemable721", [
        randomUpdateID,
        redeemableID,
        projectID,
      ]);

      tx.setGaslessOptions({
        biconomy: {
          apiKey: "DZ5cVN1Oa.91230c39-f142-460d-bd74-aaf0ea6e52b2",
          apiId: "0e3daa21-b7df-4dfb-a4c7-dccee678afdb",
          deadlineSeconds: 60,
        },
      });

      tx.send();
    }
  };

  const earn721 = async (redeemableID: string, projectID: string) => {
    if (redeemables721Contract) {
      const tx = await redeemables721Contract.prepare("earnRedeemable721", [
        redeemableID,
        projectID,
      ]);

      tx.setGaslessOptions({
        biconomy: {
          apiKey: "DZ5cVN1Oa.91230c39-f142-460d-bd74-aaf0ea6e52b2",
          apiId: "9ab690e7-8adb-4ce6-8826-3508158f2730",
          deadlineSeconds: 60,
        },
      });

      console.log(tx);

      tx.send();
    }
  };

  useEffect(() => {
    const loadContracts = async () => {
      const redeemables1155 = await sdk?.getContract(
        "0x2f4d727e018e3ae60db57cDA4B78670A8C44f7fc",
        redemptions1155ABI.abi
      );

      setRedeemables1155Contract(redeemables1155);

      const redeemables721 = await sdk?.getContract(
        "0x10dc5d77097F109677a8aE8cbDCEA63F7934CA9a",
        redemptions721ABI.abi
      );

      setRedeemables721Contract(redeemables721);

      const res1 = await redeemables1155?.call("xpContract");
      const res2 = await redeemables721?.call("xpContract");
    };

    if (sdk) loadContracts();
  }, [sdk]);

  useEffect(() => {
    const getRedeemables = async () => {
      const redeemablesResults = await redeemableClient.query({
        query: GET_PROJECT_REDEEMABLES,
        variables: {
          projectId:
            "0x15524620e34e6a3decd880a102965c6a246db258a26565449ec106b56441a4c3",
        },
      });
    };

    getRedeemables();
  }, []);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <button
        onClick={() =>
          purchase1155(
            "0x7fd9a3b40e32be74b508ffb20bbed2bf7b79909271d56fe8cd0c599cf15fc800", // Redeemable ID
            "0xe8472970fa4c25d53db67811a25a31e037a7168f719b645da63e99984701666d" // Project ID
          )
        }
      >
        Purchase redeemable (1155)
      </button>
      <br />
      <br />
      <button
        onClick={() =>
          earn1155(
            "0x977be435148ef840ff313ed080b02b268d986ec9073b962f6888626614761e35", // Redeemable ID
            "0xe8472970fa4c25d53db67811a25a31e037a7168f719b645da63e99984701666d" // Project ID
          )
        }
      >
        Earn redeemable (1155)
      </button>
      <br />
      <br />
      <button
        onClick={() =>
          purchase721(
            "redeemableIDGoesHere", // Redeemable ID
            "0x15524620e34e6a3decd880a102965c6a246db258a26565449ec106b56441a4c3" // Project ID
          )
        }
      >
        Purchase redeemable (721)
      </button>
      <br />
      <br />
      <button
        onClick={() =>
          earn721(
            "0x8a16e7de4f7cfabac02bdf9dd1f662737652796211f5c5e96f7a54e9dcf7e772", // Redeemable ID
            "0x15524620e34e6a3decd880a102965c6a246db258a26565449ec106b56441a4c3" // Project ID
          )
        }
      >
        Earn redeemable (721)
      </button>
    </div>
  );
};

export default Redeemables;
