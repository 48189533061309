import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useProSidebar } from 'react-pro-sidebar';
import { useAddress } from "@thirdweb-dev/react";
import Community from "./pages/Community/Community";
import UserProfile from "./pages/UserProfile";
import ConnectDiscord from "./pages/ConnectDiscord";
import DiscordAuth from "./pages/DiscordAuth";
import XpClaim from "./pages/XpClaim/XpClaim";
import Home from "./pages/Home/Home";
import MenuBar from "./components/MenuBar/MenuBar";
import { useQuery } from "@apollo/client";
import SidebarComponent from "./components/SidebarComponent/SidebarComponent";

import {
  getCommunityRankingCombine,
  GET_COMMUNITIES_BY_CREATED_DATE,
  GET_MY_PROJECTS,
  GET_USER_COMMUNITIES,
} from "./queries";
import { useDispatch } from "react-redux";
import {
  setUserCommunitiesAction,
  setUserProjectsAction,
  setAllVerifiedCommunities
} from "./redux/actions/CommunityActions";
import { getCommunitiesFb } from "./singletons/Firebase";
import UpdateProfileModal from "./components/UpdateProfileModal";
import Redeemables from "./pages/Redeemables";
import CommunityRedemption from "./pages/CommunityRedemption/CommunityRedemption";
import TokenRedemption from "./pages/TokenRedemption/TokenRedemption";

function App() {
  const address = useAddress();
  const dispatch = useDispatch();
  const { broken } =
    useProSidebar();

  const [openEdit, setOpenEdit] = useState(false);

  const lowerCaseAddress = address?.toLowerCase();

  const { data: communityData } = useQuery(GET_USER_COMMUNITIES, {
    variables: { address: lowerCaseAddress },
  });

  const { data: projectData } = useQuery(GET_MY_PROJECTS, {
    variables: { user: lowerCaseAddress },
  });

  const { data: communityCreated } = useQuery(GET_COMMUNITIES_BY_CREATED_DATE);

  useEffect(() => {
    if (address) {
      dispatch(setUserCommunitiesAction(communityData));
      dispatch(setUserProjectsAction(projectData));
    } else {
      dispatch(setUserCommunitiesAction([]));
      dispatch(setUserProjectsAction([]));
    }
  }, [address, dispatch, communityData, projectData]);

  useEffect(() => {
    getCommunitiesFb(getCommunities);

    return () => {
      getCommunitiesFb(getCommunities);
    };
  }, [communityCreated, address]);

  const getCommunities = async (items: any) => {
    const communityVal = Object.values(items);
    let results: any = [];

    results = await Promise.all(
      communityVal.map(async (community: any): Promise<number> => {
        let createdAt: any = null;
        if (communityCreated?.projects) {
          const created = communityCreated?.projects?.find(
            (element: any) => element.id === community?.xpId
          );
          createdAt = created?.createdAt;
        }

        let extraData: any = {}
        const obj = {
          ...community,
          createdAt,
          ...extraData,
        };

        if (typeof community?.xpId === 'string') {
          extraData = await getCommunityRankingCombine(community.xpId);
        }


        return obj;
      })
    );

    dispatch(setAllVerifiedCommunities(results));
  };

  return (
    <BrowserRouter>
      <MenuBar setOpenEdit={setOpenEdit} />

      <SidebarComponent />
      <div style={{
        display: "flex",
        paddingLeft: broken ? '0px' : '325px',
        flexDirection: "row",
      }}>

        <Routes >
          <Route path="community/:projectId" element={<Community />} />
          <Route path="community/:projectId/:redemptionId" element={<CommunityRedemption />} />
          <Route path="community/:projectId/:chainId/:redemptionId" element={<TokenRedemption />} />
          <Route path="user/:userId" element={<UserProfile />}>
            <Route path=":projectId" element={<UserProfile />} />
          </Route>
          <Route path="discord-connect" element={<ConnectDiscord />} />
          <Route path="discord-connect/auth" element={<DiscordAuth />} />
          <Route path="xpclaim/:claimId" element={<XpClaim />} />
          <Route path="redeemables" element={<Redeemables />} />
          <Route path="*" element={<Home />} />
        </Routes>
        {openEdit && <UpdateProfileModal
          open={openEdit}
          setUpdateProfileOpen={setOpenEdit}
        />}
      </div>

    </BrowserRouter>
  );
}

export default App;
