import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import RedemptionCard from '../../components/RedemptionCard/RedemptionCard';
import Lottie from "lottie-react";
import bonfire from "../../images/bonfire.json";
import Pagination from '../../components/Pagination/Pagination';
import { capitalize } from "../../functions/myFunctions";
import TokenCard from '../../components/TokenCard/TokenCard';
import { SUPPORTED_WATCHER_NETWORKS, getTokenListed } from '../../config/config'
import DropDown from '../../components/DropDown/DropDown'

const TAB_NAMES = [
  'ERC-20',
  'NFT'
]

interface Props {
  projectId: string | undefined
}

export default function RedemptionTab({ projectId }: Props) {
  const navigate = useNavigate();

  const [redeemables, setRedeemables] = useState([])
  const [redemptions, setRedemptions] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [network, setNetWork] = useState<any>(SUPPORTED_WATCHER_NETWORKS[0])
  const [isOpen, setIsOpen] = useState(false);
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(9);
  const [currentTab, setCurrentTab] = useState<string>("ERC-20");

  const lastPostIndex = currentPage * postPerPage;
  const firstPostIndex = lastPostIndex - postPerPage;

  useEffect(() => {
    getListedTokensHandler()
  }, [network])


  useEffect(() => {
    // getListedTokens()
    getRedeemables()
  }, [])

  const getListedTokensHandler = async () => {
    setCurrentPage(1);
    const data = await getTokenListed(projectId, network)
    setRedemptions(data)
  };

  const getRedeemables = async () => {
    try {
      const response = await fetch(
        "https://api.thegraph.com/subgraphs/name/bcaklovi/huddln-redemptions-matic-v1",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `
              query ($projectId: String!){
                redeemables(where:{ projectId: $projectId}, orderBy: createdAt, orderDirection: desc) {
                  id
                  redeemableId
                  projectId
                  name
                  amount
                  initialAmount
                  points
                  actionName
                  scoreType
                  requiresPurchase
                  creator
                  contractAddress
                  createdAt
                  token
                }
              }
            `,
            variables: {
              projectId,
            },
          }),
        }
      );

      if (!response.ok) {
        setRedeemables([])
        throw new Error("Error fetching data from the API");

      }

      const { data } = await response.json();

      if (data?.redeemables) {
        setRedeemables(data.redeemables);
      } else {
        setRedeemables([])
      }

    } catch (error) {
      console.log(error);
      setRedemptions([])
    } finally {
      setLoaded(true);
    }
  };

  const navTo = (id: any) => {
    navigate(
      `/community/${projectId}/${id}`
    );
  }

  const navToToken = (id: number) => {
    navigate(
      `/community/${projectId}/${network.chainId}/${id}`
    );
  }

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (n: any) => {
    setNetWork(n);
    setIsOpen(false);
  };

  const shownRedeemables = redeemables.slice(firstPostIndex, lastPostIndex);

  return (
    <div className='redemption-tab-contain'>
      <div className="flex w-full flex-row items-center justify-between">
        <div className="leaderboard-tabs-container">
          {TAB_NAMES &&
            TAB_NAMES.map((type, index) => (
              <div
                key={index}
                className={`leaderboard-tab ${currentTab === type ? 'active' : 'not-active'}`}
                onClick={() => setCurrentTab(type)}
              >
                {capitalize(type)}
              </div>
            ))}
        </div>
        {currentTab === 'ERC-20' && <DropDown
          currentItem={network}
          setCurrentItem={setNetWork}
          list={SUPPORTED_WATCHER_NETWORKS}
        />}
      </div>
      {currentTab === 'ERC-20' &&
        <div className="flex flex-1 flex-col w-full mt-5 justify-start">
          {
            redemptions.length > 0 ? (
              <div className='redemption-tab-contain-inner-column'>
                {redemptions.map((item: any) =>
                  <TokenCard key={item.id} item={item} style={{ marginBottom: 30 }} width='220px' onClick={navToToken} network={network} />
                )}

              </div>
            ) : (
              <div className='redemption-tab-contain-inner-bon'>
                <Lottie
                  animationData={bonfire}
                  loop={true}
                  style={{ height: 300 }}
                />
                <h3>No redemptions yet</h3>
              </div>
            )}
          <Pagination
            // total={allNftsLength}
            total={redemptions.length}
            perPage={postPerPage}
            setPage={setCurrentPage}
            currentPage={currentPage}
            enableEnd={false}
            onEndReach={() => { }}
          />          </div>}
      {currentTab === 'NFT' && <>   {!loaded ? (
        <></>
      ) : redeemables.length > 0 ? (
        <div className='redemption-tab-contain-inner-column'>
          {shownRedeemables.map((item: any) =>
            <RedemptionCard item={item} key={item?.id} onClick={navTo} style={{ marginBottom: 30 }} width='220px' />
          )}
        </div>
      ) : (
        <div className='redemption-tab-contain-inner-bon'>
          <Lottie
            animationData={bonfire}
            loop={true}
            style={{ height: 300 }}
          />
          <h3>No redemptions yet</h3>
        </div>
      )}
        <Pagination
          // total={allNftsLength}
          total={redeemables.length}
          perPage={postPerPage}
          setPage={setCurrentPage}
          currentPage={currentPage}
          enableEnd={false}
          onEndReach={() => { }}
        /></>}

    </div>
  )
}
