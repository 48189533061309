import "./Tabs.css";

import React from 'react'

interface Props {
  labels: string[];
  currentTab: number;
  setCurrentTab: any;
}

export default function Tabs({ labels, currentTab, setCurrentTab }: Props) {
  return (
    <div className='tab-container'>{labels.map((item, i) => (
      <div className={`tab ${currentTab === i ? 'active' : ''}`} key={item} onClick={() => {
        setCurrentTab(i)

      }
      }>
        {item}
      </div>
    ))}
      <div className="bg-huddlnPurple w-[120px] h-[4px] rounded- absolute bottom-[0px] left-[10px] transition-all duration-200 ease-in-out" style={{ borderRadius:'5px',
        transform: `translateX(${140 * currentTab}px)`
      }} />
    </div>
  )
}
