import filetype from 'magic-bytes.js'

export const abbreviateNumber = (n: any) => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

export const numberWithCommas = (x: any) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const capitalize = (s: any) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export const capitalizeWords = (s: any) => {
  const words = s.split(" ");
  const capWords = [];
  for (let i = 0; i < words.length; i++) {
    capWords[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }
  let retString = ''
  for (let i = 0; i < capWords.length; i++) {
    if (i > 0) {
      retString = retString + " ";
    }
    retString = retString + (capWords[i]);
  }
  return retString;
}

export const getMimeType = async (url: any) => {
  const options = {
    uri: url,
    gzip: true,
    encoding: null,
    method: 'GET',
    headers: {
      'Range': 'bytes=0-256' // Maximum number of bytes to download // usually 100 byte is enough
    }
  };
  let response = await fetch(url, options).then((a) => a.arrayBuffer());

  let arr = new Uint8Array(response)
  let resp;
  try {
    resp = filetype(arr)[0].mime?.split('/')[0]

  } catch (e) {
    resp = 'dne'
  }

  return resp;
};

export const truncate = (
  fullStr: string,
  strLen: number,
  separator: string
) => {
  if (!fullStr || fullStr?.length <= strLen) return fullStr;

  separator = separator || "...";

  let sepLen = separator.length,
    charsToShow = strLen - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2);

  return (
    fullStr.substr(0, frontChars) +
    separator +
    fullStr.substr(fullStr.length - backChars)
  );
};