import "./Community.css";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LeaderBoardTab from './LeaderBoardTab'
import { projectRealtimeDb } from "../../firebase/config";
import { useQuery, useApolloClient } from "@apollo/client";
import { GET_PROJECT } from "../../queries";
import Linkify from "react-linkify";
import { FiTwitter, FiShare } from "react-icons/fi";
import { FaDiscord, FaSpotify } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import Tabs from "../../components/Tabs/Tabs";
import RedemptionTab from "./RedemptionTab";

const Community = () => {
  const { projectId } = useParams();
  const client = useApolloClient();

  const [logoUrl, setLogoUrl] = useState<string>();
  const [backgroundUrl, setBackgroundUrl] = useState<string>();
  const [projectDescription, setProjectDescription] = useState<string>();
  const [projectName, setProjectName] = useState<string>();
  const [twitter, setTwitter] = useState<string>();
  const [discord, setDiscord] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [imageZoomOpen, setImageZoomOpen] = useState<boolean>(false);

  const [scoreTypes, setScoreTypes] = useState<string[]>([]);
  const [scoreTabs, setScoreTabs] = useState<string[]>([]);
  const [totalUsers, setTotalUsers] = useState<number>();
  const [totalXP, setTotalXP] = useState<number>();

  const [currentTabIndex, SetCurrentTabIndex] = useState<number>(0);

  useEffect(() => {
    getProjectDetails();
    getProjectScoreInfo()
  }, [projectId]);

  const getProjectDetails = async () => {
    if (projectId)
      await projectRealtimeDb
        .ref()
        .child("xp")
        .child("communities")
        .child(projectId)
        .once("value")
        .then((snapshot) => {
          if (snapshot.exists()) {
            let logoURL = snapshot.val().imageURI;
            let backgroundURL = snapshot.val().backgroundImageURI;
            let description = snapshot.val().description;
            let name = snapshot.val().name;
            if (logoURL) setLogoUrl(logoURL);
            if (backgroundURL) setBackgroundUrl(backgroundURL);
            if (description) {
              // description = description.replace(
              //   /((http|https|ftp|ftps)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g,
              //   '<Link href="$1">$1</Link>'
              // );
              setProjectDescription(description);
            }
            if (name) setProjectName(name);
            if (snapshot.val().twitter) setTwitter(snapshot.val().twitter);
            if (snapshot.val().discord) setDiscord(snapshot.val().discord);
            if (snapshot.val().email) setEmail(snapshot.val().email);
          }
        })
        .catch((err) => {
          console.log(err);
        });
  };

  const getProjectScoreInfo = () => {
    client
      .query({
        query: GET_PROJECT,
        variables: {
          projectId: projectId,
        },
      })
      .then((response) => {
        setScoreTypes(response.data.projects[0].scoreTypes);
        setScoreTabs(["all", ...response.data.projects[0].scoreTypes]);
        setTotalUsers(response.data.projects[0].totalUsers);
        setTotalXP(response.data.projects[0].projectTotalScore);
      })
      .catch((err) => console.error(err));
  }

  return (
    <div className="community-container" >
      <div className="community-container-inner"  >
        <div
          className="flex flex-1 justify-center items-center filter drop-shadow-[0px 0px 10px rgba(0, 0, 0, 0.8)] bg-black overflow-hidden mr-[-10px] ml-[-10px]" style={{aspectRatio:32/9,height:'auto',minWidth:300}}
        >
          <img
          
            src={backgroundUrl}
            style={{
              width: '100%',objectFit: 'cover'
            }}
            alt=''
          />
        </div>

        <div className="community-header-container" >
          <img src={logoUrl}
            className="community-user-profile"
            onClick={() => setImageZoomOpen(!imageZoomOpen)}
            alt=''
          />
          <div className="community-title">
            <div className="community-title-xp">
              <h1>{projectName}</h1>
              <div className="total-score-container">
                <div className="total-score">
                  <div className="gradient-text total-score-number">
                    {totalXP && parseInt(totalXP.toString()).toLocaleString("en-US")}
                  </div>{" "}
                  Total XP
                </div>
                <div className="total-members">
                  <div className="gradient-text total-score-number">
                    {totalUsers &&
                      parseInt(totalUsers.toString()).toLocaleString("en-US")}
                  </div>{" "}
                  Members
                </div>
              </div>
            </div>
            <div className="project-description">
              <Linkify>{projectDescription}</Linkify>
            </div>
            <div className="project-social-icons-contain">
              {twitter && (
                <a
                  className="project-social-icon"
                  rel="noreferrer"
                  href={twitter}
                  target="_blank"
                >
                  <FiTwitter size='20px' />
                </a>
              )}
              {discord && (
                <a className="project-social-icon"
                  rel="noreferrer" href={discord} target="_blank">
                  <FaDiscord size='24px' />
                </a>
              )}
              {email && (
                <a className="project-social-icon"
                  rel="noreferrer" href={"mailto:" + email} style={{ paddingBottom: 3 }}>
                  <MdOutlineEmail size='22px' />
                </a>
              )}
            </div>
          </div>
        </div >
        <div className="community-tab" >
          <Tabs
            labels={['Leaderboards', 'Redemptions']}
            currentTab={currentTabIndex}
            setCurrentTab={SetCurrentTabIndex}
          />
        </div>
        <div className="community-body" >
          {currentTabIndex === 0 && <LeaderBoardTab
            scoreTypes={scoreTypes}
            scoreTabs={scoreTabs}
            projectId={projectId}
          />}
          {currentTabIndex === 1 && <RedemptionTab
            projectId={projectId}
          />}
        </div>
      </div>
    </div>
  );
};

export default Community;
