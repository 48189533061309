import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ConnectWallet, useAddress } from "@thirdweb-dev/react";
import verifiedPurpleImg from "../../images/verificationPurple.png";
import verifiedBlueImg from "../../images/verificationBlue.png";
import { firestoreGetUserInfoV4, } from "../../utility";
import { truncate } from '../../functions/myFunctions'
import { ethers } from "ethers";

import identicon1 from "../../images/avatar9.png";
import identicon2 from "../../images/avatar8.png";
import identicon3 from "../../images/avatar7.png";
import identicon4 from "../../images/avatar6.png";
import identicon5 from "../../images/avatar5.png";
import identicon6 from "../../images/avatar4.png";
import identicon7 from "../../images/avatar3.png";
import identicon8 from "../../images/avatar22.png";
import identicon9 from "../../images/avatar23.png";

import "./UserAvatar.css"
import { capitalize } from "../../functions/myFunctions";

const extendSize = 2;
const identicons = [
  identicon1,
  identicon2,
  identicon3,
  identicon4,
  identicon5,
  identicon6,
  identicon7,
  identicon8,
  identicon9,
];


interface Props {
  address: any;
  size?: any;
  infoPlacement?: String;
  enableShadow?: boolean;
  enableInfo?: boolean;
  borderWidth?: string;
  borderColor?: string;
  shadowColor?: string;
  withVerification?: boolean,
  onClick?: any
};

const UserAvatar = ({
  address,
  size = 40,
  infoPlacement = 'side',
  enableShadow = false,
  enableInfo = false,
  borderWidth = '3px',
  borderColor = 'white',
  shadowColor = 'black',
  withVerification = true,
  onClick
}: Props) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [userImageURI, setUserImageURI] = useState('')
  const [userName, setUserName] = useState('')
  const [socials, setSocials] = useState<any>({})
  const [verification, setVerification] = useState<any>({})


  useEffect(() => {
    getUserData()
  }, [address])

  const getUserData = async () => {
    const checksumAddress = ethers.utils.getAddress(address);
    try {
      const data = await firestoreGetUserInfoV4(checksumAddress);
      const { firstName: fName, lastName: lName, userImageURI: image, userName: uName, socials: s, verification: v } = data

      fName && setFirstName(fName)
      lName && setLastName(lName)
      image && setUserImageURI(image)
      uName && setUserName(uName)
      s && setSocials(s)
      v && setVerification(v)
    } catch (error) {
    }
  }

  const onClickHandler = () => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <div style={{
      filter: enableShadow ? `drop-shadow(0px 0px 10px ${shadowColor})` : undefined,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      cursor: 'pointer',
      flexDirection: infoPlacement == 'bottom' ? 'column' : 'row'
    }}
      onClick={onClickHandler}>
      <div style={{ position: 'relative' }} >

        <div className="img-container" style={{

          height: size,
          width: size,
          border: `${borderWidth} solid ${borderColor}`,
          borderRadius: '50%',
          overflow: "hidden"
        }}>


          {
            !!userImageURI &&
            <img className="img" src={userImageURI} />}



          { //fallback 1
            socials?.discordProfile_info?.avatar &&
            <img className="img" src={socials.discordProfile_info.avatar} />}

          { //fallback 2 avatar generate
            !socials?.discordProfile_info?.avatar && !userImageURI &&
            <img className="img" src={identicons[Math.floor(Math.random() * 8)]} />}
        </div>

        {!!verification?.level && withVerification &&
          <div className="verified-container">
            {
              verification?.level == 3 &&
              <img className="img-style" src={verifiedPurpleImg} />
            }
            {
              verification?.level == 2 &&
              <img className="img-style" src={verifiedBlueImg} />
            }
          </div>
        }
      </div>

      {enableInfo &&
        <div
          style={{ display: 'flex', width: '100%', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', margin: infoPlacement == "bottom" ? "10px 0px 0px 0px" : "0px 0px 0px 10px" }}>

          {//NATIVE FIRST NAME LASTNAME AVAILABLE
            !!firstName && !!lastName &&
            <>
              <div className="info-first-slot" >{capitalize(firstName) + ' ' + capitalize(lastName)}</div>
            </>

          }

          {//NATIVE USERNAME AVAILABLE
            !!userName &&
            <>
              <div className="info-second-slot" style={{ display: 'flex', textAlign: 'center', maxWidth: '90%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }} >{userName}</div>
            </>

          }

          {// 1st falllback, discord username is available but not first name lastname or username
            (!firstName && !lastName) && (socials?.discord_id) && (socials?.discord_id) && (socials?.discordProfile_info?.username) &&

            <div className="info-first-slot" style={{ display: 'flex', textAlign: 'center', maxWidth: '90%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}  >{socials.discordProfile_info.username}</div>

          }
        </div>
      }
      {/* {(!firstName && !lastName) && (!socials?.discord_id) && (!socials?.discord_id) && (!socials?.discordProfile_info?.username) && address &&//last fallback for name nothing available so use address
        <div className="info-second-slot" style={{ display: 'flex', textAlign: 'center', maxWidth: '90%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{truncate(address, 12, "...")}</div>

      } */}
    </div >

  );
};

function propsAreEqual(prev: any, next: any) {
  return prev.address === next.address
}

export default React.memo(UserAvatar, propsAreEqual);
